import {Link} from "react-router-dom";
import {FaRegEnvelope} from "react-icons/fa";
import {Section} from "./section";
import React from "react";

interface ContactBannerProp{
    children?: React.ReactNode;
}
const ContactBanner: React.FC<ContactBannerProp> = ({children}) => {
    return(
        <Section className="mt-8 flex flex-wrap flex-col items-center justify-center pb-10">
            <div className="mb-2">
               {children}
            </div>
            {/* TODO: Add redirect link */}
            <Link to={``} className='flex items-center tracking-widest justify-center rounded border-4 px-2 text-xl font-bold text-primary-main border-primary-main py-2.5 bg-white h-[60px] w-[400px] max-[420px]:w-full'>
                <FaRegEnvelope className="mr-2 h-7 w-7"/> お問い合わせ
            </Link>
        </Section>
    )
}
export default ContactBanner;