import React from "react";
import Image from "../../assets/noimage.jpg"
import {numberWithCommas} from "../../utlis/numbers";


interface HistoryLayoutProp {
  date?: string;
  children?: React.ReactNode;
}

export const HistoryLayout: React.FC<HistoryLayoutProp> = ({ children, date }) => {
  return (
    <div className='mt-5 border-b border-accent-bg'>
      <p className='text-sm text-secondary-text'>{date}2023年2月28日</p>
      <ul className='my-2'>
        {children}
      </ul>
    </div>
  );
};

interface HistoryDataProp {
  type: 'TAX' | 'DIVIDEND' |'DEPOSIT' |'RETURN';
  typeDisplay: string;
  fundName?: string;
  amount?: string;
  className?: string;
}
const plusList = ['DIVIDEND', 'RETURN'];
const minusList = ['TAX'];

const typeColors = {
  'TAX': '#191919',
  'DIVIDEND': '#EA547E',
  'RETURN': '#191919',
  'DEPOSIT': '#FCD21D',
};
const typeImage = {
  'TAX': Image,
  'DIVIDEND': '#EA547E',
  'RETURN': '#191919',
  'DEPOSIT': '#FCD21D',
};
export const HistoryData: React.FC<HistoryDataProp> = ({ type, typeDisplay, fundName, amount }) => {
  return (
    <li className='pt-1 pb-3'>
      <div className='flex items-center justify-between'>
        <div className="flex items-center">
          <img className="w-8 h-8 border" src={ typeImage[type] } alt="image"/>
          <div className='text-primary-text px-2 text-xl'>{typeDisplay}</div>
        </div>
        <div className='text-right'>
          <p className='text-sm font-normal text-opacity-50 text-primary-text'>{fundName || ''}</p>
          <p style={{ color: typeColors[type] || '' }}><span>{plusList.includes(type) ? '＋' : minusList.includes(type) ? '-' : ''}</span>{numberWithCommas(amount)}円</p>
        </div>
      </div>
    </li>
  );
};
