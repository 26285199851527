import React from "react";

export type RecruitingType = {
  id: number;
  units: number
  //booked_units: number;
  units_remainder: number;
  start: string;
  end: string;
  name: string;
  name_display: string;
  result_date: string;
  is_finished: boolean;
}

export type FundType = {
  id?: number;
  slug?: string;
  name?: string;
  status?: string;
  capital_interest?: string;
  income_interest?: string;
  investment_amount?: number;
  operation_start?: string;
  operation_end?: string;
  total_distributions?: number;
  amount_raised?: number;
  total_investment?: number;
  number_of_units?: number;
  min_num_unit?: number;
  max_num_units?: number;
  subordinated_investment?: string;
  transaction_type?: string;
  duration?: string;
  duration_type?: string;
  recruiting?: RecruitingType[];
  current_recruitment?: RecruitingType | null;
  next_recruitment?: RecruitingType | null;
  image?: string;
  unit?: number;
}

export type ComingSoonDetailType = {
  title: string
  sub_title: string,
  note: string,
  published: string
}

export type ComingSoonFundType = {
  thumbnail: string;
  detail: ComingSoonDetailType;
}

export type FundListType = {
  funds?: FundType[];
  comingSoon?: ComingSoonFundType | null;
}

export type AgreementType = {
  first: boolean,
  second: boolean,
  third: boolean
}

export const defaultAgreement: AgreementType = {
  first: false,
  second: false,
  third: false,
};

export type FundApplyType = {
  fund: string;
  used_point: number;
  unit: number;
}


