import React from "react"

interface SectionProps {
    className?: string;
    children: React.ReactNode;
}
export const Section: React.FC<SectionProps> = ({
                                                    children,
                                                    className="my-10"
}) => {
    return(
        <div className={`${className} px-4 sm:px-6`}>
            {children}
        </div>
    )
}