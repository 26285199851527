import React, {useState, useEffect} from "react";
import CampaignBanner from "../../components/ui/CampaignBanner";
import MyNumberLink from "../../components/ui/MyNumberLink";
import {Section} from "../../components/layouts/section";
import {FaRegEnvelope} from "react-icons/fa";
import {TableData, TableHeader} from "../../components/layouts/tableLayout";
import {Link} from "react-router-dom";
import ContactBanner from "../../components/layouts/ContactBanner";
import {DepositType, initialDepositWithdraw} from "../../store/transaction";

const Deposit: React.FC = () => {
  const [state, setState] = useState<DepositType>(initialDepositWithdraw);
  useEffect(() => {
    const fetchData = async () => {
      const fetchedData: DepositType = {
        vaBankName: "GMOあおぞらネット銀行",
        vaBranchName: "xx支店　(支店番号:000)",
        vaType: "普通",
        vaAccountNumber: "0000000",
        vaHolderNameKana: "ソウケンジュウハン（カ",
      };
      setState(fetchedData);
    };
    fetchData();
  }, []);
  return (
    <React.Fragment>
      <CampaignBanner/>
      <MyNumberLink/>
      <Section className="mt-10">
        <TableHeader title="振込先のご案内">
          <TableData name="銀行名">
            <p>{state.vaBankName}</p>
          </TableData>
          <TableData name="支店名">
            <p>{state.vaBranchName}</p>
          </TableData>
          <TableData name="口座種別">
            <p>{state.vaType}</p>
          </TableData>
          <TableData name="口座番号">
            <p>{state.vaAccountNumber}</p>
          </TableData>
          <TableData name="受取人口座名">
            <p>{state.vaHolderNameKana}</p>
          </TableData>
        </TableHeader>
      </Section>
      <Section>
        <h3>《重要》</h3>
        <ul className="square leading-7 mt-2 tracking-widest">
          <li>支店名・口座番号は定期的に変更されます。</li>
          <li>お振込の際は、必ずご本人様名義の銀行口座からお振込ください。</li>
          <li>ご本人様名義と異なる口座から入金された場合、組戻の手続きが必要となります。<br/>組戻には、お客様負担での手数料がかかる場合がございます。
          </li>
        </ul>
      </Section>
      <Section>
        <h3>《注意事項》</h3>
        <ul className="square leading-7 mt-2 tracking-widest">
          <li>振込手数料はお客様負担となります。</li>
          <li>お客様の資金を弊社に信託している期間中においては利息は付与されません。</li>
          <li>口座から出金する場合は、ログイン後「入出金ページ」より払戻申請を行ってください。</li>
        </ul>
      </Section>
      <ContactBanner>
        <p className="py-4">お振込みに関して、ご不明点等ございましたらこちらからお問い合わせください。</p>
      </ContactBanner>
    </React.Fragment>
  )
}

export default Deposit;