import React from 'react';

interface InputRowProps  {
  title?: string;
  className?: string;
  children?: React.ReactNode;
}

export const InputRow: React.FC<InputRowProps> = ({
                                                      title,
                                                      children,
                                                      className=''
}) => {
  return (
    <dl className={`${className} border-b-0 border last:border-b border-accent-bg flex items-stretch flex-row w-full`}>
      <dt
        className='max-[500px]:px-2 px-4 bg-secondary-bg py-2 font-normal md:border-none max-[500px]:w-24 w-40 sm:w-48'>
        {title}
      </dt>
      <dd className='flex-1 bg-white border-l border-accent-bg max-[500px]:px-2  px-4'>
        {children}
      </dd>
    </dl>
  );
};