import {ChoiceOptionType} from './index';
import {resolveSrv} from "dns";

export interface LoginInformationType {
  email: string;
}

export interface BankAccountType {
  account_holder: string;
  account_number: string;
  account_type: '普通' | '当座';
  bank_hiragana?: string;
  bank_id?: number;
  bank_name: string;
  branch_hiragana?: string;
  branch_id?: number;
  branch_name: string;
}

export interface PersonalInformationType {
  last_name: string;
  first_name: string;
  last_name_kana: string;
  first_name_kana: string;
  sex: '男' | '女' | '';
  phone_number: string;
  postal_code: string;
  prefecture: string;
  city_ward: string;
  block_name: string;
  address4?: string | null;
  birth_date?: string;
}

export interface MemberInformationType {
  occupation: string;
  workplace: string;
  annual_income: number;
  financial_assets: number;
  investment_year: string;
  investment_funds: string;
  income_source?: string;
}

export type PasswordChangeType = {
  password: string;
  new_password1: string;
  new_password2: string
};

export type EmailChangeType = {
  email: string;
  otp: string;
};

export const assetTypeOptions: ChoiceOptionType[] = [
  {value: '現金・預金', label: '現金・預金'},
  {value: '株式・社債', label: '株式・社債'},
  {value: '投資信託', label: '投資信託'},
  {value: '不動産', label: '不動産'},
  {value: 'その他', label: 'その他'},
];


export type AssetType = {
  asset_name?: string;
  asset_amount?: string;
};

export const accountTypeOptions: ChoiceOptionType[] = [
  {value: '普通', label: '普通'},
  {value: '当座', label: '当座'},
];

export const investmentFundsOptions: ChoiceOptionType[] = [
  {value: '余剰資金', label: '余剰資金'},
  {value: '退職金', label: '退職金'},
  {value: '借入資金', label: '借入資金'},
];
export const genderOptions: ChoiceOptionType[] = [
  {value: '男', label: '男'},
  {value: '女', label: '女'},
];

export const occupationOptions: ChoiceOptionType[] = [
  {value: '会社員', label: '会社員'},
  {value: '会社役員', label: '会社役員'},
  {value: '自営業', label: '自営業'},
  {value: '主婦', label: '主婦'},
  {value: '学生', label: '学生'},
  {value: '年金受給者', label: '年金受給者'},
  {value: '無職', label: '無職'},
  {value: 'その他', label: 'その他'},
];

export const yearsOfInvestOptions: ChoiceOptionType[] = [
  {value: '未経験', label: '未経験'},
  {value: '〜1年', label: '〜1年'},
  {value: '1〜3年', label: '1〜3年'},
  {value: '3〜5年', label: '3〜5年'},
  {value: '5〜10年', label: '5〜10年'},
  {value: '10年以上', label: '10年以上'},
];
export const purposeOfInvestOptions: ChoiceOptionType[] = [
  {value: '老後資金', label: '老後資金'},
  {value: '教育資金', label: '教育資金'},
  {value: '娯楽費', label: '娯楽費'},
  {value: '生活費', label: '生活費'},
  {value: 'その他', label: 'その他'},
];
export const incomeSourceOptions: ChoiceOptionType[] = [
  {value: '給与所得', label: '給与所得'},
  {value: '年金', label: '年金'},
  {value: '配当', label: '配当'},
  {value: 'その他', label: 'その他'},
];

export const investmentPolicyOptions: ChoiceOptionType[] = [
  {value: '短期', label: '短期'},
  {value: '長期', label: '長期'},
];
export const experienceChoice: ChoiceOptionType[] = [
  {value: '株式・投資信託', label: '株式・投資信託'},
  {value: '不動産投資', label: '不動産投資'},
  {value: '不動産投資信託', label: '不動産投資信託'},
  {value: '不動産特定共同事業', label: '不動産特定共同事業'},
  {value: '金', label: '金'},
  {value: '保険', label: '保険'},
  {value: 'その他', label: 'その他'},
];

export type MyNumberType = '0' | '1' | '2';

export type MyNumberImageType = {
  front: string | ArrayBuffer | null;
  back: string | ArrayBuffer | null;
  resident: string | ArrayBuffer | null;
}

export type MyNumberImageDataType = {
  image_data: string | ArrayBuffer | null;
  image_type: string;
}

export type MyNumberDataType = {
  status: string;
  mynumber_images: MyNumberImageDataType[];
}

export interface InvestorRegistrationType extends PersonalInformationType, MemberInformationType {
  investment_policy: string;
  investment_purpose?: string;
  investment_experience: string[];
  asset_type: AssetType[];
}
export type InvestmentStatusFundType = {
  name: string;
  slug:string;
  amount_of_investment?: number;
  unit?: number;
  image?: string;

}
export type InvestmentCancelFundType = {
  name: string;
  note:string;
}

export type InvestmentPaginationType = {
  data: InvestmentStatusFundType[];
  lastPage: number;
}


export type InvestmentStatusType = {
  currentFunds: InvestmentStatusFundType[];
  finishedFunds: InvestmentStatusFundType[];
  canceledFunds: InvestmentCancelFundType[];
  chartData?: (string | number)[][]
}

export type InvestmentDetailDividendType = {
  receive_date: string;
  order: number;
  receive_money: number;
}

export type InvestmentDetailFundType = InvestmentStatusFundType & {
  def_time: string;
  duration: string;
  duration_type: string;
  operation_start: string;
  operation_end: string;
  percent: number;
  fund_type: 'T' | 'R';
  dividends: InvestmentDetailDividendType[];
  next_dividend: InvestmentDetailDividendType;
  paper_file: string | null;
}

export type UserTradingHistoryType = {
  type: 'TAX' | 'DIVIDEND' |'DEPOSIT' |'RETURN';
  type_display: string;
  amount: string;
  fund: string;
}

export type TradingHistoryType = {
  date: string;
  data: UserTradingHistoryType[];
}

export type TradingHistoryPaginationType = {
  num_pages: number;
  data: TradingHistoryType[];
}
