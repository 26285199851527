import React, { useState } from 'react';
import { FaPlus,FaMinus } from "react-icons/fa";

export interface SectionHeaderProps {
  title?: string;
  className?: string;
  color?: string;
  width?: string;
  children?: React.ReactNode;
}

export const FaqLayout: React.FC<SectionHeaderProps> = ({ title, className, children }) => {
  const [showModel, setShowModel] = useState(false);

  const handleModelClick = () => {
    setShowModel(!showModel);
  };

  return (
    <div className={`border-b last:border-b-0 py-3`}>
      <div
        className={`${className} flex w-full items-center justify-between pt-1 cursor-pointer transition ease-in-out delay-150`}
        onClick={handleModelClick}>
        <h3 className='text-xl font-normal px-2'>{title}</h3>
        <div className='items-end'>
          <FaPlus className={`h-4 w-4 fill-primary-main mr-3 ${showModel ? 'hidden' : ''}`} />
          <FaMinus className={`h-4 w-4 fill-primary-main mr-3 ${showModel ? '' : 'hidden'}`} />
        </div>
      </div>
      {showModel && (
        <div className={`mt-3 px-2 text-base transition ease-in-out delay-150`}>
          {children}
        </div>
      )}
    </div>
  );
};