import {FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight} from "react-icons/fa";
import React, {useMemo} from "react";

export const Pagination: React.FC<{
  total?:number;
  perPage?:number;
  page?: number;
  setCurrentPage?: (newPage: number) => void;
}> = ({ page = 1, total= 0,  perPage=0, setCurrentPage }) => {

  const totalPages = useMemo(() => Math.ceil(total / perPage), [total, perPage]);

  return (
    <div className="pagination flex flex-row justify-center mt-4">
      <ul className="flex items-center gap-2 text-xs">
        <li
          className={`bg-accent-bg hover:bg-primary-bg px-4 py-2 
            ${page === 1 ? "cursor-not-allowed" : "cursor-pointer"}
          `}
          onClick={() => setCurrentPage?.(1)}
        >
          <FaAngleDoubleLeft className="h-3.5" />
        </li>
        <li
          className={`flex items-center bg-secondary-bg hover:bg-primary-bg px-4 py-2
            ${page === 1 ? "bg-secondary-bg cursor-not-allowed" : " cursor-pointer"}
          `}
          onClick={() => setCurrentPage?.(page - 1)}
        >
          <FaAngleLeft />
          前へ
        </li>
        <li>
          <span className="border border-accent-main h-10"></span>
        </li>
        <li
          className={`flex items-center bg-secondary-bg hover:bg-primary-bg px-4 py-2
            ${page === totalPages ? "cursor-not-allowed" : "cursor-pointer"}
          `}
          onClick={() => setCurrentPage?.(page + 1)}
        >
          次へ
          <FaAngleRight />
        </li>
        <li
          className={`bg-accent-bg hover:bg-primary-bg px-4 py-2 ${
            page === totalPages ? "bg-accent-bg cursor-not-allowed" : "cursor-pointer"
          }`}
          onClick={() => setCurrentPage?.(totalPages)}
        >
          <FaAngleDoubleRight className="h-3.5" />
        </li>
      </ul>
    </div>
  );
};