import React, {useState} from "react";
import CampaignBanner from "../components/ui/CampaignBanner";
import MyNumberLink from "../components/ui/MyNumberLink";
import {Section} from "../components/layouts/section";
import {Link} from "react-router-dom";
import Image from "../assets/noimage.jpg"
import Logo from "../assets/logo.png"
import {FaSignOutAlt} from "react-icons/fa";

const Menu: React.FC = () => {
  return (
    <React.Fragment>
      <CampaignBanner/>
      <MyNumberLink/>
      <Section>
        <h2>山田 太郎 様</h2>
        <p className="text-secondary-text">ID：1234567</p>
        <div className="mt-10">
          <ul className="grid gap-10 grid-cols-2 sm:grid-cols-2">
            <li className="border-4 border-primary-main text-center bg-secondary-main">
              <Link className="flex flex-col items-center p-4" to={"/account"}>
                <img className="w-10 h-32" src={Image}/>
                <h3>登録情報</h3>
                <p>各種登録情報の確認変更はこちら</p>
              </Link>
            </li>
            <li className="border-4 border-primary-main text-center bg-secondary-main">
              {/* TODO: link */}
              <Link className="flex flex-col items-center p-4" to={""}>
                <img className="w-10 h-32" src={Image}/>
                <h3>交付書面</h3>
                <p>各種交付書面の確認はこちら</p>
              </Link>
            </li>
            <li className="border-4 border-primary-main text-center bg-secondary-main">
              <Link className="flex flex-col items-center p-4" to={"/news"}>
                <img className="w-10 h-32" src={Image}/>
                <h3>お知らせ</h3>
                <p>会員様向けのお知らせ一覧</p>
              </Link>
            </li>
            <li className="border-4 border-primary-main text-center bg-secondary-main">
              {/* TODO: link */}
              <Link className="flex flex-col items-center p-4" to={""}>
                <img className="w-10 h-32" src={Image}/>
                <h3>よくある質問</h3>
                <p>お客様からいただいたよくある質問はこちら</p>
              </Link>
            </li>
            <li className="border-4  col-span-2 sm:col-span-2 border-primary-main bg-secondary-main ">
              {/* TODO: link */}
              <Link className="flex flex items-center p-4" to={""}>
                <img className="w-10 h-32" src={Image}/>
                <div>
                  <h3>カスタマーサポート</h3>
                  <p>メールまたは電話で問い合わせる</p>
                </div>
              </Link>
            </li>
          </ul>
        </div>
        <Link to="" className="flex flex-col items-center my-10">
          <img className="w-48" src={Logo} alt="logo"/>
          <p>みつばちファンドのサイトへ戻る</p>
        </Link>
      </Section>
      <Section className={"bg-secondary-bg mb-10 py-4"}>
        <Link to={""}>
          <p className={"flex items-center text-2xl font-bold"}><FaSignOutAlt className="mr-1"/> ログアウト</p>
          <p>ログアウトしてサイトへ戻る</p>
        </Link>
      </Section>
    </React.Fragment>
  )
}

export default Menu;