import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Link} from "react-router-dom";
import {FaInfoCircle, FaChevronCircleRight} from "react-icons/fa";
import Image from "../assets/noimage.jpg"
import Select from "../components/ui/form/SelectField";
import {numberWithCommas} from '../utlis/numbers';
import {extractMonth, extractYear, getMonthsUntilNextYear} from '../utlis/date';
import 'chartjs-plugin-datalabels';
import {Section} from "../components/layouts/section";
import {ChoiceOptionType} from "../store";
import MyNumberLink from "../components/ui/MyNumberLink";
import CampaignBanner from "../components/ui/CampaignBanner";
import {ErrorsType} from "../components/ui/form/Error";
import {HomeNews} from "./news/HomeNews";
import {TabLayout} from "../components/layouts/TabLayout";
import {InvestmentStatus} from "./investmentStatus";
import PostService from "../infrastructure/ap1/posts/post";
import {MyPagePostsType} from "../store/post";
import axios from "axios";

interface DividendTitleLayoutProp {
  year?: string | number;
  children?: React.ReactNode;
}

interface DividendDataProp {
  month: string | number;
  amount: string | number;
}

const DividendTitleLayout: React.FC<DividendTitleLayoutProp> = ({children, year}) => {
  return (
    <Section className="my-3">
      <h2 className='text-lg font-bold text-primary-dark sm:text-xl'>{year}年</h2>
      <ul
        className='mt-3 grid grid-cols-2 gap-y-4 gap-x-10 mb-5'>
        {children}
      </ul>
    </Section>
  );
};
const DividendData: React.FC<DividendDataProp> = ({month, amount}) => {
  return (
    <li className='border-b border-accent-bg flex justify-between items-center py-0.5'>
      <p className="text-lg">{month}月</p>
      <p
        className={` ${amount ? 'text-primary-main' : 'text-primary-text'} text-right text-xl`}>{numberWithCommas(amount) || 0}<span>円</span>
      </p>
    </li>
  );
};


const Dividend: React.FC = () => {
  const [posts, setPosts] = useState<MyPagePostsType>()
    const memorizedYearMonth = useMemo(() => getMonthsUntilNextYear(), []);
  const postService = new PostService(axios)

 const fetchPosts = useCallback(() => {
    postService
      .getMyPageHomeNews()
      .then((data) => { setPosts(data) })}, [])

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

   const groupedDividend = [
    {year: "2023", month: "9", total_receive: 15000},
    {year: "2023", month: "12", total_receive: 18000},
    {year: "2023", month: "3", total_receive: 20000},
    {year: "2024", month: "1", total_receive: 22000},
    {year: "2024", month: "7", total_receive: 25000},
  ];

  return (
    <React.Fragment>
      {Object.keys(memorizedYearMonth).map((key) => (
        <DividendTitleLayout key={key} year={key}>
          {memorizedYearMonth[key].map((month, index) => {
            const dividend = groupedDividend.find(
              (val) => extractYear(val.year) === key && extractMonth(val.month) === month.toString()
            );
            const amount = dividend ? dividend.total_receive : 0;
            return <DividendData key={index} month={month} amount={amount}/>;
          })}
        </DividendTitleLayout>
      ))}
      <div className="text-right text-xs px-4">
        <p>※実際の配当金額は、所得税及び復興所得税(20.42%）を控除させていただきます。</p>
        <p>※記載の数値は想定です。変更となる可能性があります。</p>
      </div>
      <HomeNews posts={posts?.news || []}/>
    </React.Fragment>
  );
};

export const AssetsTypeOptions: ChoiceOptionType[] = [
  {value: '0', label: '資産総額'},
  {value: '1', label: '累計配当額'},
  {value: '2', label: '出資総額'},
];

const Home: React.FC = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [errors, setErrors] = useState<ErrorsType>();
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(e.target.value);
  };

  let divContent = "";
  let divtext = "";

  if (selectedValue === "資産総額") {
    divContent = "1,585,000";
    divtext= "";
  } else if (selectedValue === "累計配当額") {
    divContent = "3,000";
    divtext= "";
  } else if (selectedValue === "出資総額") {
    divContent = "Content for Option 3";
    divtext= "";
  }
  return (
    <React.Fragment>
      <CampaignBanner/>
      <MyNumberLink/>
      {/* TODO: get data */}
      <Section className="py-3">
        <Link to="/membership" className="flex justify-between items-end cursor-pointer">
          <div className="flex ">
            <figure className="h-9 w-9 sm:h-16 sm:w-16">
              <img className="h-full w-full object-cover" src={Image} alt=""/>
            </figure>
            <div>
              <span className="text-sm sm:text-base">会員ランク</span>
              <h2 className="text-primary-dark text-xl sm:text-2xl">メープルシロップ</h2>
            </div>
          </div>
          <div
            className="underline underline-offset-4 font-bold flex sm:text-2xl text-xl gap-1">ランク詳細 <span><FaInfoCircle
            className="fill-secondary-text h-3 w-3 sm:h-4  sm:w-4"/></span></div>
        </Link>
      </Section>

      {/* TODO: total assets  */}
      <Section className="py-3 sm:py-5 bg-primary-bg">
        <div className="flex justify-between">
          <Select
            label="資産総額"
            labelClass="text-xl"
            name="assets_type"
            options={AssetsTypeOptions}
            labelKey='label'
            valueKey='value'
            onChange={handleSelectChange}
            className="mb-8"
            errors={errors}
            width="w-[150px]"
          />
          <div className="self-end text-end">
            <p className="text-3xl sm:text-4xl font-bold mb-2">{divContent}<span className="font-normal text-lg">円</span></p>
            <span className="sm:text-lg tracking-widest">内みつばちポイント / 5,000pt</span>
          </div>
        </div>
      </Section>

      <Section className="py-3 sm:py-5 bg-secondary-bg">
        <div className="flex justify-between py-3">
          <div>
            <h2 className="sm:text-lg">デポジット</h2>
            <p className="text-sm sm:text-base">（未出資金）</p>
          </div>
          <div className="self-end text-end">
            <Link to="/deposit"
                  className="border-2 border-accent-main text-accent-main rounded-md px-5 font-bold py-2">入金</Link>
            <p className="text-3xl sm:text-4xl font-bold mb-1 mt-5">100,000<span
              className="font-normal text-lg">円</span></p>
            <Link to="/withdraw-history"
                  className="sm:text-lg justify-end items-center underline underline-offset-4 tracking-widest flex mt-2">払戻申請中<span><FaChevronCircleRight
              className="fill-primary-main"/></span></Link>
          </div>
        </div>
      </Section>
      <div>
        <TabLayout tabs={['配当予定', 'ポートフォリオ']}>
          <Dividend/>
          <InvestmentStatus/>
        </TabLayout>
      </div>
    </React.Fragment>
  )
}
export default Home;