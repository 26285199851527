import React, {useState} from "react";

type FundPrivacyCheckTitleProp = {
  title?: string;
  className?:string;
  children?: React.ReactNode;
  setAgreement?: () => void;
  isRead?: boolean | undefined;
}
export const FundAgreementCheckLayout: React.FC<FundPrivacyCheckTitleProp> = ({ title, className, children, setAgreement, isRead }) => {
  const [showModel, setShowModel] = useState(false);

  const handleModelClick = () => {
    if (!showModel && setAgreement){
      setAgreement();
    }
    setShowModel(!showModel);
  };

  return (
      <div className={`px-1 transform transition-all duration-1000 ease-in-out last:border-b-2 border-t-2 ${(showModel || isRead) ? 'border-primary-dark' : 'border-accent-brown'}`}>
      <div
        className={`${className} flex w-full items-center bg-white justify-between p-2 py-4 cursor-pointer transition duration-1000 ease-in-out`}
        onClick={handleModelClick}>
        <p className='sm:text-xl'>{title}</p>
        <div className='items-end'>

         <input
            type="checkbox"
            checked={showModel || isRead}
            className="w-6 h-6 accent-pink-500 transform transition-all duration-1000 ease-in-out"
          />
        </div>
      </div>
      {showModel && (
        <div
          className='mt-2 mb-10 overflow-hidden px-4 py-4 bg-secondary-bg h-[300px] transform transition-all duration-1000 ease-in-out'>
          {children}
        </div>
      )}
    </div>
  );
};