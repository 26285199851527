import React, {useEffect, useState} from "react";
import {Section} from "../../components/layouts/section";
import {ErrorsType} from "../../components/ui/form/Error";
import {useAccount} from "../account/accountDummy";
import {
  genderOptions, InvestorRegistrationType,
} from "../../store/account";
import {InputField} from "../../components/ui/form/InputField";
import {RadioButton} from "../../components/ui/form/RadioButton";
import {Link} from "react-router-dom";
import {useStepper} from "../../hooks/useStepper";
import useForm from "../../hooks/useForm";

export const RegistrationStepOne: React.FC = () => {
  const {incrementCurrentStep} = useStepper();
  const {investorRegistrationType} = useAccount();

  const {inputValues, handleChange, errors} = useForm<InvestorRegistrationType, ErrorsType>(investorRegistrationType, () => {
    incrementCurrentStep();
  })

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <form>
      <Section className="mt-5">
        <h2 className="text-xl mb-1">投資家基本情報</h2>
        <p className="text-base leading-10">
          以下の項目を全て入力してください。
        </p>
      </Section>
      <Section>
        <div className="flex max-[450px]:flex-col gap-x-6">
          <InputField
            value={inputValues.first_name || ''}
            label="姓"
            onChange={handleChange}
            name="first_name"
            width='max-[500px]:w-full w-1/2'
            suggestion="※漢字で入力してください。"
            errors={errors}
            required
          />
          <InputField
            value={inputValues.last_name || ''}
            label="名"
            onChange={handleChange}
            name="last_name"
            width='max-[500px]:w-full w-1/2 '
            errors={errors}
            required
          />
        </div>
        <div className="flex max-[450px]:flex-col gap-x-6">
          <InputField
            label="セイ"
            onChange={handleChange}
            name='last_name_kana'
            value={inputValues.last_name_kana || ''}
            width='max-[500px]:w-full w-1/2 '
            errors={errors}
            suggestion="※カタカナで入力してください。"
            required
          />
          <InputField
            label="メイ"
            onChange={handleChange}
            name='first_name_kana'
            value={inputValues.first_name_kana || ''}
            width='max-[500px]:w-full w-1/2'
            errors={errors}
            required
          />
        </div>
        <div className="pt-3">
          <label>性別</label>
          <RadioButton
            options={genderOptions}
            labelKey='label'
            valueKey='value'
            value={inputValues.sex}
            onChange={handleChange}
            name='sex'
            checked
          />
        </div>
        <InputField
          label="生年月日"
          name='birth_date'
          type='date'
          onChange={handleChange}
          value={inputValues.birth_date || ''}
          width='max-[500px]:w-full w-1/2 pt-3 '
          errors={errors}
          required
        />
        <InputField
          name='phone_number'
          label="電話番号"
          type='text'
          onChange={handleChange}
          value={inputValues.phone_number || ''}
          width='max-[500px]:w-full w-1/2 pt-3 '
          suggestion="※半角数字で入力してください。"
          errors={errors}
          required
        />
        <InputField
          name='postal_code'
          label="郵便番号"
          type='text'
          onChange={handleChange}
          value={inputValues.postal_code || ''}
          width='max-[500px]:w-full w-1/2 pt-3'
          className="pl-4"
          errors={errors}
          suggestion="※半角数字で入力してください。"
          required
          icon="〒"
        />
        <div className="flex max-[500px]:flex-col justify-between mt-4"><span>ご住所</span><span
          className="text-xs text-primary-light">※ご本人確認書類の情報と同じ内容を入力してください。</span></div>
        <InputField
          name='prefecture'
          type='text'
          onChange={handleChange}
          value={inputValues.prefecture || ''}
          width='max-[500px]:w-full'
          placeholder="都道府県"
          errors={errors}
          required
        />
        <InputField
          name='city_ward'
          type='text'
          onChange={handleChange}
          value={inputValues.city_ward || ''}
          width='max-[500px]:w-full'
          placeholder="市区町村"
          errors={errors}
          required
        />
        <InputField
          name='block_name'
          type='text'
          onChange={handleChange}
          value={inputValues.block_name || ''}
          width='max-[500px]:w-full'
          placeholder="番地"
          suggestion="※番地以降を正しく入力してください。"
          errors={errors}
          required
        />
        <InputField
          name='address4'
          type='text'
          onChange={handleChange}
          value={inputValues.address4 || ''}
          width='max-[500px]:w-full'
          placeholder="マンション・アパート名 部屋番号"
          suggestion="※部屋番号まで正しく入力してください。"
          errors={errors}
          required
        />

      </Section>
      <div className=" mb-10 px-6 w-[350px] max-[350px]:w-full mx-auto">
        <div
          className='mt-8 mb-8  flex flex-col items-center justify-center'>
          <button
            onClick={incrementCurrentStep}
            type='submit'
            className='flex items-center tracking-widest justify-center rounded border-4 px-2 text-base sm:text-lg font-bold text-white bg-accent-main border-accent-main py-2.5 w-full'>
            投資家情報入力へ進む
          </button>
        </div>
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-8 bg-white text-gray-500">Or</span>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <Link to=""
                className="border-2 rounded border-secondary-text text-white font-bold flex justify-center bg-secondary-text items-center text-center py-3 w-52 h-10 mt-8">ログアウト</Link>
        </div>
      </div>
    </form>
  )
}
