import React from 'react';
import { ErrorList, ErrorListProps } from './Error';

interface RadioButtonProps<T extends object> extends Omit<ErrorListProps, 'checked' | 'onChange'> {
  value: string | number;
  label?: string;
  checked: boolean;
  options: T[];
  labelKey: keyof T;
  valueKey: keyof T;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  required?: boolean;
  showRequired?: boolean;
}

export const RadioButton = <T extends object>({
                                                  value,
                                                  label,
                                                  name,
                                                  onChange,
                                                  options,
                                                  labelKey,
                                                  valueKey,
                                                  errors,
                                                  required = true,
                                                  showRequired,
                                              }: RadioButtonProps<T>): React.ReactElement => {
    return (
    <div className='mt-3'>
     {label &&
         <label className='text-base'> {label}
           {showRequired && label &&
              <span className='text-accent-main text-sm'> ※必須</span>
          }
      </label>}
      <div className='mb-3 flex flex-wrap gap-2 sm:gap-5'>
        {options.map((data, index) => (
          <label className='flex items-center' key={index}>
            <input
              type='radio'
              value={data[valueKey] as string}
              name={name}
              checked={value === data[valueKey]}
              onChange={(e) => onChange(e, index)}
              className='mr-2 h-5 w-5 accent-sky-600'
              required={required}
            />
            {data[labelKey] as string}
          </label>
        ))}
      </div>
        <ErrorList name={name} errors={errors} />
    </div>
  );
};