import React, {useEffect} from "react";
import {Section} from "../../components/layouts/section";
import {Link} from "react-router-dom";
import {useStepper} from "../../hooks/useStepper";

export const RegistrationStepThree: React.FC = () => {
  const {decrementCurrentStep} = useStepper();
  const handleCompletion = () => {
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Section className="mt-5">
        <h2 className="text-xl mb-1">本人確認書類の提出</h2>
        <p className="text-base leading-10">
          お手元に以下のいずれかをご準備いただき、カメラ付きスマートフォンにて本人確認を進めてください。
        </p>
        <div className="border border-accent-bg mt-5">
          <p className="text-center font-bold bg-secondary-bg my-4 py-2">本人確認書類</p>
          <p className="text-primary-light pl-3">《最短5分で登録完了》</p>
          <div className="flex flex-row gap-10 pl-5">
            <ul className="list-disc ml-4">
              <li>マイナンバーカード</li>
              <li>運転免許証</li>
            </ul>
            <ul className="list-disc">
              <li>在留カード</li>
              <li>住民基本台帳カード</li>
              <li>運転経歴証明書</li>
            </ul>
          </div>
          <div className="mb-5 mt-4 pl-5">
            <p className="text-primary-main">※1週間前後で審査完了</p>
            <ul className="list-disc ml-4">
              <li>保険証</li>
            </ul>
          </div>
        </div>
        <div className="mt-5 pt-5 text-[#333333]">
          <h3 className="pb-2">《PCをご利用の場合》</h3>
          <p
            className="pl-3 mb-8">次のページで表示されるQRコードをスマートフォンで読み込み、撮影を進めてください。</p>
          <h3 className="pb-2">《その他注意事項》</h3>
          <ul className="star flex gap-4 flex-col">
            <li>本人確認の審査手続きには数時間〜数日かかる場合もございます。ご了承ください。</li>
            <li>登録時にご入力いただいたご住所と、本人確認書類のご住所が一致しない場合、エラーとなってしまいます。その場合、<Link
              to="" className="text-accent-main underline-offset-4 underline">こちら</Link>からご住所を変更していただき、本人確認書類のご提出をお願いいたします。
            </li>
            <li>保険証での本人確認をご希望の場合、登録されているご住所にハガキを郵送いたします。ハガキの到着を持って投資家登録完了となります。登録完了までに1週間ほどお時間いただく場合がございます。ご了承ください。</li>
          </ul>
        </div>
        <div className="mb-10 pt-5 px-6 w-[450px] max-[450px]:w-full mx-auto">
          <div
            className='mt-8 mb-8  flex flex-col items-center justify-center'>
            <button
              onClick={handleCompletion}
              type='submit'
              className='flex items-center tracking-widest justify-center rounded border-4 px-2 text-base sm:text-lg font-bold text-white bg-accent-main border-accent-main py-2.5 w-full'>
              本人確認をはじめる
            </button>
          </div>
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-8 bg-white text-gray-500">Or</span>
            </div>
          </div>
          <div className="flex items-center justify-center gap-4">
            <button
              type='button'
              onClick={decrementCurrentStep}
              className="border-2 rounded border-secondary-text text-white flex justify-center bg-secondary-text items-center text-center py-3 w-52 h-10 mt-8">
              投資家情報へ戻る
            </button>
            <Link
              to=""
              className="border-2 rounded border-secondary-text text-white  flex justify-center bg-secondary-text items-center text-center py-3 w-52 h-10 mt-8">
              ログアウト
            </Link>
          </div>
        </div>
      </Section>
    </div>
  )
}