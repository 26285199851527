import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Section} from "../../components/layouts/section";
import {PostPaginationType} from "../../store/post";
import {NewsData} from "./NewsData";
import {useSearchParams} from "react-router-dom";
import CampaignBanner from "../../components/ui/CampaignBanner";
import MyNumberLink from "../../components/ui/MyNumberLink";
import PostService from "../../infrastructure/ap1/posts/post";
import axios from "axios";
import {Pagination} from "../../components/ui/Pagination";

const limit = 2;
const News: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [posts, setPosts] = useState<PostPaginationType>();
  const page = useMemo(() => parseInt(searchParams.get('page') || '1'), [searchParams]);
  const postService = new PostService(axios);

  const fetchPosts = useCallback(()=> {
    postService
      .getMyPageNews("news", page, limit)
      .then((data) => {setPosts(data)})
  },[page])

  useEffect(() => {
     fetchPosts();
  }, [fetchPosts]);

  const setCurrentPage = (page: number) => {
      setSearchParams({ page: page.toString() });
    }

  return (
    <React.Fragment>
        <CampaignBanner/>
        <MyNumberLink/>
      <Section>
        <h2 className="text-xl mb-4">お知らせ一覧</h2>
        <div className="border-y-2 border-accent-bg ">
          <ul>
            {posts?.data.map((data, index) => (
              <NewsData
                className="last:border-b-0"
                key={index}
                {...data}
              />
            ))}
          </ul>
        </div>
        <Pagination
          total={posts?.count}
          perPage={limit}
          page={page}
          setCurrentPage={setCurrentPage}
        />
      </Section>
    </React.Fragment>
  );
};

export default News;