import React, {ReactNode, useState} from "react";
import {Link} from "react-router-dom";
import {FaPlus, FaMinus} from "react-icons/fa";

interface DocumentListProps {
  date?: string;
  name?: string;
  slug?: string;
  className?: string;
  financialReport?: boolean;
  annualReport?: boolean;
}

interface DocumentHeaderProps {
  title?: string;
  className?: string;
  color?: string;
  width?: string;
  children?: React.ReactNode;
}

interface DocumentTabProps {
  tabs: string[];
  className?: string;
  children: ReactNode[];
}

export const DocumentTabLayout: React.FC<DocumentTabProps> = ({
                                                                tabs,
                                                                className,
                                                                children
                                                              }) => {
  const [activeTab, setActiveTab] = useState(0);
  if (!children || children.length === 0) {
    return null;
  }
  return (
    <div>
      <div className={`${className} flex justify-around items-center `}>
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`py-3 px-4 sm:px-10 text-xl sm:text-2xl font-bold ${
              index === activeTab
                ? 'text-primary-text border-b-8 border-solid border:drop-shadow-md border-primary-main' : 'text-accent-bg'}`}
            onClick={() => setActiveTab(index)}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="py-4 bg-white border-t-4 border-primary-bg -mt-1.5">{children[activeTab]}</div>
    </div>
  );
};

export const DocumentHeader: React.FC<DocumentHeaderProps> = ({
                                                                title,
                                                                className,
                                                                children
                                                              }) => {
  const [showModel, setShowModel] = useState(false);

  const handleModelClick = () => {
    setShowModel(!showModel);
  };

  return (
    <div className={`${showModel ? '' : 'border-b'} py-3`}>
      <div
        className={`${className} flex w-full items-center justify-between pt-1 cursor-pointer transition ease-in-out delay-150`}
        onClick={handleModelClick}>
        <h3 className='text-xl font-normal px-2'>{title}</h3>
        <div className='items-end'>
          <FaPlus className={`h-4 w-4 fill-accent-main mr-3 ${showModel ? 'hidden' : ''}`}/>
          <FaMinus className={`h-4 w-4 fill-accent-main mr-3 ${showModel ? '' : 'hidden'}`}/>
        </div>
      </div>
      {showModel && (
        <div className={`mt-3 px-2 text-base transition ease-in-out delay-150`}>
          {children}
        </div>
      )}
    </div>
  );
};

export const DocumentsList: React.FC<DocumentListProps> = ({
                                                             date,
                                                             name,
                                                             slug = "",
                                                             className,
                                                             annualReport,
                                                             financialReport,
                                                           }) => {
  return (
    <div className={`${className} flex justify-between items-center`}>
      <div>
        <time className="text-secondary-text text-sm">閲覧日｜{date}2023年3月14日 12:35</time>
        <h3 className="text-lg">{name}個人情報の取り扱いに関する同意事項</h3>
      </div>
      {annualReport && <div>
          <time className="text-secondary-text text-sm">2022年</time>
      </div>}
      {financialReport && <div>
          <p className="text-secondary-text text-sm">第35期決算報告書</p>
      </div>}
      <Link className="border-4 rounded-xl font-bold border-primary-dark py-2 px-4 bg-white text-primary-dark"
            to={slug}>閲覧</Link>
    </div>
  )
}