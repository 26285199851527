import React, {useState} from "react";
import CampaignBanner from "../../../components/ui/CampaignBanner";
import MyNumberLink from "../../../components/ui/MyNumberLink";
import {ApplicationStep} from "./ApplicationStep";
import {Section} from "../../../components/layouts/section";
import {FaPlusCircle, FaCheck} from "react-icons/fa";
import {Link} from "react-router-dom";
import {ApplicationCompleted} from "./ApplicationCompleted";

export type ApplicationConformationProp = {
  name?: string;
  unit?: string;
  investAmount?: string;
  point?: string;
  completed: boolean;
  handleApply: () => void;
  handleBack: () => void;
}
const ApplicationConformation: React.FC<ApplicationConformationProp> = (state) => {
  const [step, setStep] = useState({ confirm:false, apply: false });
  const handleConfirm = () => {
        setStep((prevStep) => ({
        ...prevStep,
        confirm: true
      }));
     }
    return(
        <React.Fragment>
          {
        !step.confirm ?
        <div>
        <ApplicationStep step={2} />
          <Section>
            <h2 className="text-xl">出資申込内容確認</h2>
            <p className="text-base leading-10">お申し込み内容をご確認ください。</p>

              <dl className="mt-5">
                <dt className="tracking-widest pt-4 border-t border-accent-bg">
                  出資申込内容確認
                </dt>
                <dd className="mb-5 flex justify-end flex-col ">
                  <h2 className="text-end text-xl tracking-widest">{state.name}</h2>
                  <button type="button" className="justify-end items-center text-primary-dark tracking-widest flex mt-1">
                    募集概要を確認する
                    <span><FaPlusCircle className="fill-primary-main h-5 w-5"/></span>
                  </button>
                </dd>
                <dt className="tracking-widest pt-4 border-t border-accent-bg">
                  出資口数
                </dt>
                <dd className="mb-5 flex justify-end flex-col ">
                  <p className="text-end text-xl tracking-widest">{state.unit}口</p>
                </dd>

                <dt className="tracking-widest pt-4 border-t border-accent-bg">
                  利用ポイント
                </dt>
                <dd className="mb-5 flex justify-end flex-col ">
                  <h2 className="text-end text-xl tracking-widest">{state.point}pt</h2>
                </dd>
                <dt className="tracking-widest pt-4 border-t border-accent-bg">
                  出資金額
                </dt>
                <dd className="mb-5 flex justify-end flex-col ">
                  <h2 className="text-end text-xl tracking-widest">{state.investAmount}円</h2>
                </dd>
                <dt className="tracking-widest pt-4 border-t border-accent-bg">
                  出資申込内容確認
                  <dd className="mb-5 flex w-full items-end flex-col ">
                      <p className="flex items-center">個人情報保護方針<FaCheck className="fill-accent-main "/></p>
                      <p className="flex items-center">契約成立前書面<FaCheck className="fill-accent-main "/></p>
                      <p className="flex items-center">電子取引業務に係る重要事項<FaCheck className="fill-accent-main "/></p>
                    </dd>
                </dt>
              </dl>
          </Section>
          <Section className="bg-secondary-bg py-2 my-6">
              <h3>注意事項</h3>
          </Section>
          <Section className="mb-20">
            <ul className="star text-red-500 leading-8 tracking-widest">
              <li >抽選でのお申し込みの場合、お一人様1回限りとさせていただきます。ご了承ください。</li>
              <li>抽選申込の場合、当選と同時に契約締結となるため当選発表後のキャンセルはできません。契約締結後のキャンセルはクーリングオフ対象となります。</li>
              <li>出資完了後に利用ポイント数を変更することはできません。みつばちポイントを利用する場合は「戻る」ボタンから出資申込画面に戻り、利用ポイント数を入力してください。</li>
            </ul>

            <div className="flex max-[400px]:flex-col flex-row justify-center items-center gap-5 mt-8 mb-10">
              <Link
                  to='/'
                  className='flex flex-col items-center justify-center rounded border-4 px-2 text-sm lg:text-lg font-bold text-secondary-text border-secondary-text py-2.5 bg-white h-[60px] w-[300px] max-[300px]:w-full'>
                    入力画面に戻る
              </Link>
               <button
                   type="button"
                   onClick={handleConfirm}
                   className='flex items-center justify-center rounded border-4 px-2 lg:text-lg font-bold text-white bg-accent-main border-accent-main py-2.5 h-[60px] w-[300px] max-[300px]:w-full'>
                    出資する
               </button>
           </div>
          </Section>
          </div>: <ApplicationCompleted/>  }
        </React.Fragment>
    )
}
export default ApplicationConformation;