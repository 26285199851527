import React, {useEffect, useState} from 'react';
import {useLiveQuery} from 'dexie-react-hooks';
import CampaignBanner from "../../../components/ui/CampaignBanner";
import MyNumberLink from "../../../components/ui/MyNumberLink";
import {convertToNumber, numberWithCommas} from '../../../utlis/numbers';
import {ApplicationStep} from "./ApplicationStep";
import {Section} from "../../../components/layouts/section";
import {useFund} from "../fundDummy";
import {Link, useParams} from "react-router-dom";
import {FundData} from "../FundData";
import {FaPlusCircle} from "react-icons/fa";
import {dummyUser} from "../../account/accountDummy";
import {InputField} from "../../../components/ui/form/InputField";
import {ErrorList, ErrorsType} from "../../../components/ui/form/Error";
import {FundAgreement} from "./FundAgreement";
import {AgreementType, defaultAgreement} from "../../../store/fund";
import ApplicationConformation from "./ApplicationConformation";
import {AdvanceNotice} from "./AdvanceNotice";


interface Option {
  value: boolean;
  label: string;
  checked?: boolean;
}

const UsePoint: Option[] = [
  {value: true, label: 'はい'},
  {value: false, label: 'いいえ'},
];

const FundApplication: React.FC = () => {
  const user = useLiveQuery(() => dummyUser, []);
  const {funds, comingSoon} = useFund();
  const {slug} = useParams();
  const [errors, setErrors] = useState<ErrorsType>();
  const fund = funds.find((val) => val.slug === slug);
  const [usePoint, setUsePoint] = useState(false);
  const [usedPoint, setUsedPoint] = useState(0);
  const [units, setUnits] = useState<number>();
  const [disabled, setDisabled] = useState(true);
  const [agreement, setAgreement] = useState<AgreementType>(defaultAgreement);
  const [step, setStep] = useState({confirm: false, apply: false});

  useEffect(() => {
    if (agreement.first && agreement.second && agreement.third) {
      setDisabled(false);
    }
  }, [agreement.first, agreement.second, agreement.third]);

  if (!fund) {
    return <p>Fund not found</p>;
  }
  const handleChange = () => {

  };

  const handleConfirm = () => {
    setStep((prevStep) => ({
      ...prevStep,
      confirm: true
    }));
  }
  const handleApply = () => {

  }
  return (
    <React.Fragment>
      <CampaignBanner/>
      <MyNumberLink/>
      {
        !step.confirm ?
          <div>
            <ApplicationStep step={1}/>
            <FundData fund={fund}/>
            <Section className="mb-5 flex justify-end">
              {/* TODO : button action */}
              <button type="button"
                      className="sm:text-lg justify-end items-center text-primary-dark tracking-widest flex mt-2">
                募集概要を確認する
                <span><FaPlusCircle className="fill-primary-main h-6 w-6"/></span>
              </button>
            </Section>
          {/* TODO : condition apply to advance notice */}
            <AdvanceNotice/>

            <Section className="pt-10">
              <h3 className="text-2xl">出資申込内容入力</h3>
              <p
                className="text-base leading-10">デポジットの金額をご確認の上、希望する出資口数を半角数字で入力してください。</p>
            </Section>
            <Section className="py-2 sm:py-3 bg-secondary-bg my-2">
              <div className="flex justify-between py-3">
                <div>
                  <h2 className="sm:text-lg">デポジット</h2>
                  <p className="text-sm sm:text-base">（未出資金）</p>
                </div>
                <div className="self-end text-end">
                  <Link to="/deposit"
                        className="border-2 border-accent-main text-accent-main rounded-md px-5 font-bold py-2">入金</Link>
                  <p className="text-3xl sm:text-4xl font-bold mb-1 mt-5">{numberWithCommas(user?.deposit)}<span
                    className="font-normal text-lg">円</span></p>
                  <p className="sm:text-lg justify-end items-center tracking-widest flex mt-2">保有中みつばちポイント
                    : <span className="text-primary-main font-bold">{numberWithCommas(user?.point) || 0}pt</span></p>
                </div>
              </div>
            </Section>
            <Section className="bg-secondary-bg mt-10 py-4">
                <p>お客様は現在<strong>{fund.name}</strong>の抽選募集に<span className="text-accent-main font-bold">{numberWithCommas(fund.investment_amount)}円</span>(1口)申込中です。</p>
            </Section>
            <Section>
              <div className="text-xl">出資希望口数<span
                className="text-accent-main text-base">*{fund.min_num_unit}〜{fund.max_num_units}口まで</span></div>
              <InputField
                value={20}
                onChange={handleChange}
                name="kuchi"
                style="text-right mr-5 py-2 px-2 font-bold text-3xl"
                iconRight="口"
                required
                width='w-full'
                errors={errors}
              />
              <div className="text-xl mt-4">出資金額</div>
              <InputField
                value={20}
                onChange={handleChange}
                name="kuchi"
                className="border-b-2 border-0 rounded-none"
                style="text-right mr-5 py-1 px-2 font-bold text-3xl"
                iconRight="円"
                required
                errors={errors}
              />

              <div className="mt-3">
                <p>みつばちポイントを利用しますか？<span className="text-accent-main text-base">*必須</span></p>
                <div className='mb-3 mt-2 flex flex-wrap gap-2 sm:gap-5'>
                  {UsePoint.map((data, index) => (
                    <label className='flex items-center'>{data.label}
                      <input
                        type="radio"
                        key={index}
                        value='use_point'
                        name='point'
                        className='mr-2 h-5 w-5 accent-sky-600'
                        checked={data.value === usePoint}
                        onChange={() => {
                          setUsePoint(data.value);
                        }}
                      />
                    </label>
                  ))}
                </div>
              </div>

              <div className="flex justify-between pt-3 gap-5 items-center">
                <div>
                  <p className="text-sm text-primary-light">《保有ポイント数：{numberWithCommas(user?.point)}pt》</p>
                  <p className="text-xl font-bold">利用ポイント：</p>
                </div>
                <div className="flex-1 flex relative border border-accent-bg rounded disabled:opacity-50">
                  <input
                    type='text'
                    className={`w-full text-right mr-6 py-2 px-2 font-bold text-3xl rounded focus:outline-none ${
                      user && usedPoint > user.point ? 'border-red-500' : ''
                    }`}
                    value={numberWithCommas(usedPoint)}
                    onChange={(e) => {
                      const newValue = convertToNumber(e.target.value) || 0;
                      if (!user || newValue <= user.point) {
                        setUsedPoint(newValue);
                      }
                    }}
                  />
                  <span className="absolute right-0 bottom-0 text-lg pb-1.5 font-bold text-accent-bg mr-1">pt</span>
                </div>
              </div>
              <ErrorList name='used_point' errors={errors}/>
            </Section>
            <Section>
              <div className="text-center font-bold sm:text-xl pt-5 mb-5">▼以下の内容を必ずご確認ください▼</div>
              <div>
                <FundAgreement setAgreement={setAgreement} agreement={agreement}/>
              </div>
              <p className='mt-10 text-center'>上記の内容で出資申込をする場合は <span
                className="max-[600px]:block hidden"></span>「出資内容確認」ボタンを押してください。</p>

              <div className="flex max-[400px]:flex-col flex-row justify-center items-center gap-5 mt-8 mb-10">
                <Link
                  to='/'
                  className='flex flex-col items-center justify-center rounded border-4 px-2 text-sm lg:text-lg font-bold text-secondary-text border-secondary-text py-2.5 bg-white h-[60px] w-[300px] max-[300px]:w-full'>
                  マイページ<span className="max-[460px]:block hidden"></span>トップに戻る
                </Link>
                <button
                  type="button"
                  disabled={disabled}
                  onClick={handleConfirm}
                  className='flex items-center justify-center rounded border-4 px-2 lg:text-lg font-bold text-white bg-accent-main border-accent-main py-2.5 h-[60px] w-[300px] max-[300px]:w-full'>
                  出資内容確認
                </button>
              </div>
            </Section>
          </div> :
          <ApplicationConformation
            name={fund.name}
            unit={numberWithCommas(units)}
            handleApply={handleApply}
            completed={step.apply}
            handleBack={() => {
              setStep((prev) => ({...prev, confirm: false}))
            }}
          />
      }
    </React.Fragment>
  );
};

export default FundApplication;