import React from "react";
import CampaignBanner from "../../components/ui/CampaignBanner";
import MyNumberLink from "../../components/ui/MyNumberLink";
import {Section} from "../../components/layouts/section";
import {FaAngleRight} from "react-icons/fa";
import ContactBanner from "../../components/layouts/ContactBanner";
import {Link} from "react-router-dom";
import {FaqLayout} from "../../components/layouts/FaqLayout";
import Deposit from "../../assets/icon-payment.png"
import Withdraw from "../../assets/icon-withdrawal.png"

const Transaction: React.FC = () => {
  return (
    <React.Fragment>
      <CampaignBanner/>
      <MyNumberLink/>
      <Section className="mt-10 mb-10 ">
        <h2 className="text-xl mb-5">入出金</h2>
        <Link to="/deposit" className="flex relative flex-row mb-8 items-end">
          <div className="w-10 sm:w-16 flex flex-col">
            <p className="text-xs text-secondary-text mb-2">入金</p>
            <img className={"h-7 w-8 sm:h-9 sm:w-10"} src={Deposit} alt={""}/>
          </div>
          <div className="flex-1">
            <p className="font-bold text-lg sm:text-xl">みつばちファンドへの入金</p>
            <span className="text-sm sm:text-base text-secondary-text">出資金の振込先確認はこちら</span>
          </div>
          <FaAngleRight className="absolute right-0 sm:h-8 sm:w-8 text-accent-bg"/>
        </Link>
        <Link to="/withdraw" className="flex relative flex-row items-end">
          <div className="w-10 sm:w-16 flex flex-col">
            <p className="text-xs text-secondary-text mb-2">出金</p>
            <img className={"h-7 w-8 sm:h-9 sm:w-10"} src={Withdraw} alt={""}/>
          </div>
          <div className="flex-1">
            <p className="font-bold text-lg sm:text-xl">指定口座への出金</p>
            <span className="text-sm sm:text-base text-secondary-text">払戻申請はこちら</span>
          </div>
          <FaAngleRight className="absolute right-0 sm:h-8 sm:w-8 text-accent-bg"/>
        </Link>
      </Section>
      {/* TODO: Faq text */}
      <Section className="py-8">
        <h2 className="text-xl mb-2">入出金に関するFAQ</h2>
        <div className="border-y-2 border-accent-bg">
          <FaqLayout title="振り込み手数料はいくらですか？">
            <p>原則リアルタイムで反映されますが、銀行によってはお時間がかかる場合がございます。</p>
          </FaqLayout>
          <FaqLayout title="振り込み手数料はいくらですか？">
            <p>原則リアルタイムで反映されますが、銀行によってはお時間がかかる場合がございます。</p>
          </FaqLayout>
        </div>
      </Section>
      <ContactBanner>
        <p>その他、ご不明点等ございましたら<br/>こちらからお問い合わせください。</p>
      </ContactBanner>
    </React.Fragment>
  )
}

export default Transaction;