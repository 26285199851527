import React, {useState} from "react";
import CampaignBanner from "../../components/ui/CampaignBanner";
import MyNumberLink from "../../components/ui/MyNumberLink";
import {Section} from "../../components/layouts/section";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from 'date-fns/locale/ja';
import {Simulate} from "react-dom/test-utils";
import {TradingHistoryPaginationType} from "../../store/account";
import {HistoryData, HistoryLayout} from "./TradingHistoryData";
import {Pagination} from "../../components/ui/Pagination";
registerLocale('ja', ja);


const Index: React.FC = () => {
  const [state, setState] = useState<TradingHistoryPaginationType>({ data: [], 'num_pages': 0 });
  const [startDate, setStartDate] = useState(new Date());
  const change = () =>{

  }
  return (
    <React.Fragment>
      <CampaignBanner/>
      <MyNumberLink/>
      <Section className="mt-10 mb-10 ">
        <div className="border-b-4 flex justify-between items-center pb-3">
          <h2 className="text-2xl ">取引履歴</h2>
            <DatePicker
              locale='ja'
              dateFormat='yyyy年MMMM'
              selected={startDate}
              onChange={change}
              showMonthYearPicker
              className='max-[350px]:w-full sm:w-full flex-1 cursor-default appearance-none rounded bg-white px-2 py-2 leading-tight shadow hover:border-gray-400 focus:shadow-outline focus:outline-none'
            />
          </div>
      </Section>
      <Section>
        <HistoryLayout>
            <HistoryData
              typeDisplay="源泉徴収"
              type="TAX"
              fundName="みつばち1号ファンド"
              amount="110"
            />
          <HistoryData
              typeDisplay="源泉徴収"
              type="DIVIDEND"
              fundName="みつばち1号ファンド"
              amount="11000000000"
            />
        </HistoryLayout>
        <Pagination/>
      </Section>
      <Section className="bg-secondary-bg py-3">
        <div className="flex flex-col items-center">
          <p className="text-sm">2023年度</p>
          <h3　className="text-xl mb-1 tracking-widest text-secondary-text">取引履歴をCSVで取得</h3>
          <div className="mb-4">
          <a
            href="+ '/history/csv/'"
            className='flex w-full py-2 items-center justify-center rounded-md border-2 bg-white px-6 text-sm font-bold text-primary-dark border-primary-dark'>
            ダウンロード
          </a></div>
        </div>
      </Section>
    </React.Fragment>
  )
}

export default Index;