import React from "react";
import {StepperState} from "../../../store/stepper";

type StepperHeaderItemType = {
	index: number,
	name: string,
	completed: boolean,
	length: number;
}


const StepperHeaderItem:React.FC<StepperHeaderItemType> = (props) => {
	const {
		index,
		name,
		completed,
	} = props;
	return (
		<li
			className="relative w-full flex flex-col items-center justify-center gap-2"
		>
			<span className={`${index === 0 || completed ? "font-bold text-primary-light" : "text-primary-dark"}`}>{name}</span>
			<span className="flex items-center">
				<span className={`h-7 w-7 rounded-full z-10 border-4 border-primary-main ${ index === 0 || completed ? "bg-primary-main text-primary-main" : "bg-white"}`}/>
				<span className={`absolute bg-primary-main w-full h-1 ${ index > 0 && "left-[-50%]"} ${completed ? "text-primary-main" : ""}`}  />
			</span>
		</li>
	)
}

export const StepperHeader:React.FC<StepperState> = ({steps, currentStep}) => {
	const length = steps.length;
	return (
		<nav >
			<ol className="flex w-full m-auto relative items-center">
				{
					steps.map((step, index) => (
						<StepperHeaderItem key={step.id} index={index} name={step.name} completed={currentStep >= index} length={length}/>
					))
				}
			</ol>
		</nav>
	)
}