import React from 'react';

export interface ProgressProps {
  progress: number;
  color?: string;
  border?: string;
  height?: string;
  label?: boolean;
  unit?: string;
  showPercentage?: boolean;
}

export const ProgressBar: React.FC<ProgressProps> = ({
                                                         progress,
                                                         color = 'bg-secondary-main text-primary-main',
                                                         border = 'border-secondary-main',
                                                         height = 'h-6',
                                                         label,
                                                         unit,
                                                         showPercentage,
                                                  }) => {
  const progressBar = progress > 100 ? 100 : progress;
  return (

    <div className='mb-1 px-1 sm:px-0'>
      <div className='flex items-center justify-between text-base sm:text-sm pb-0.5'>
        <span className="text-secondary-text">出資率:
            <span className='text-primary-main font-bold'>{progress ? progress.toFixed(2) : progress}％</span>
        </span>
        {label && (<span className="text-secondary-text">1次募集終了済</span>)}
      </div>
      <div className={`${height} relative w-full overflow-hidden rounded-full border bg-white ${border}`}>
        <div
          className={`min-h-full transition-width transition-slowest ease rounded-full ${color}`}
          style={{ width: `${progressBar}%` }}>
          {showPercentage === true && (
            <div className='absolute flex h-full w-full items-center justify-center py-2 text-[sm]'>
              {progress ? progress.toFixed(2) : progress}%
            </div>)}
        </div>
      </div>
      {unit && (<div className='text-center text-secondary-text text-sm pt-0.5'>残り口数:{unit}口</div>)}
    </div>
  );
};