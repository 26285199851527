import React, {useEffect, useState} from 'react';
import {NewsType} from "../../store/post";
import {Link, useParams} from "react-router-dom";
import {Section} from "../../components/layouts/section";
import {Pagination} from "../../components/ui/Pagination";
import PostService from "../../infrastructure/ap1/posts/post";
import axios from "axios";
import {editorJSParser} from "../../utlis/editor";
const NewsDetails: React.FC = () => {
  const [post, setPost] = useState<NewsType | undefined>(undefined);
  const { slug } = useParams<{ slug: string }>();
  const postService = new PostService(axios)

  useEffect(() => {
    if(slug){
      postService
        .getMyPageNewsDetails("news", slug)
        .then((data) => {setPost(data);
        })
        .catch(()=>{
          // TODO: redirect to 404 page
        })
    }
  }, [slug]);

   const htmlArray: string[] = post ? editorJSParser.parse(post.content) : [];

  if (!post) {
    return <div>News not found</div>;
  }
  return (
    <Section>
      <div className={` border-b-2 w-full border-accent-bg py-3`}>
        <time className="text-xs text-secondary-text">{post.created_date}</time>
        <h3 className="text-lg leading-7">{post.title}</h3>
      </div>
      <div className="border-b-2 py-5 mb-8" >
        {
        htmlArray.map((val, index) => (
          <div key={index} dangerouslySetInnerHTML={{__html: val}} className="my-7"/>
        ))
      }
      </div>
      <Pagination/>
      <div className="flex items-center justify-center">
          <Link
            to="/news"
            className="border-2 rounded border-primary-dark text-primary-dark font-bold flex justify-center items-center text-center py-3 w-52 h-10 mt-8">
            お知らせ一覧
          </Link>
        </div>
    </Section>
  );
};

export default NewsDetails;