import React from "react";


const CampaignBanner: React.FC = () => {
    return(
       <div className="bg-primary-main">
            <div className="px-4 h-11 flex items-center">
                <p className="whitespace-nowrap overflow-x-scroll no-scrollbar sm:text-lg text-white">

                みつばちファンド1周年記念！100万円以上の出資で《Amazonギフト券5,000円分》プレゼントキャンペーン実施中！
                 </p>
            </div>
       </div>
    )
}
export default CampaignBanner;