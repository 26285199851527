import React from 'react';
import { ErrorList, ErrorListProps } from './Error';

interface MultipleSelectCheckboxProps<T extends object> extends ErrorListProps {
  options: T[];
  label: string;
  values: string[];
  labelKey: keyof T;
  valueKey: keyof T;
  className?: string;
  onChange: (values: string[]) => void;
  required?: boolean;
}

const CheckBox = <T extends object>({
                                      label,
                                      options,
                                      labelKey,
                                      valueKey,
                                      name,
                                      className,
                                      onChange,
                                      values,
                                      errors,
                                      required = true,
                                    }: MultipleSelectCheckboxProps<T>): React.ReactElement => {
  const handleOptionChange = (optionId: string) => {
    if (values.includes(optionId)) {
      onChange(values.filter((id) => id !== optionId));
    } else {
      onChange([...values, optionId]);
    }
  };

  return (
    <div className='w-full my-3 lg:w-3/4'>
      <label>{label} {required && <span className='text-accent-main'> ※必須</span>}</label>
      <ErrorList name={name} errors={errors} />
      <div className={`${className} mt-3`}>
        {options.map((option, index) => (
          <label key={index} className='flex items-center'>
            <input
              type='checkbox'
              name={name}
              value={option[valueKey] as string}
              checked={values.includes(option[valueKey] as string)}
              onChange={() => handleOptionChange(option[valueKey] as string)}
              className='mr-2 w-4 h-4 accent-pink-500'
            />
            {option[labelKey] as string}
          </label>
        ))}
      </div>
    </div>
  );
};

export default CheckBox;