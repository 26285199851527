import React, {useState} from "react";
import CampaignBanner from "../../components/ui/CampaignBanner";
import MyNumberLink from "../../components/ui/MyNumberLink";
import {Section} from "../../components/layouts/section";
import {InputField} from "../../components/ui/form/InputField";
import {ErrorsType} from "../../components/ui/form/Error";
import {useAccount} from "./accountDummy";
import {accountTypeOptions, BankAccountType} from "../../store/account";
import {RadioButton} from "../../components/ui/form/RadioButton";
import {Link, useNavigate} from "react-router-dom";

const BankAccount: React.FC = () => {
  const navigate = useNavigate();
  const {bankAccount} = useAccount();
  const [state, setState] = useState<BankAccountType>(bankAccount);
  const [errors, setErrors] = useState<ErrorsType>();
  const handleCompletion = () => {
    navigate('/withdraw');
  };
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const {name, value} = e.target;
    setState((prev) => ({...prev, [name]: value}));
  };
  return (
    <React.Fragment>
      <CampaignBanner/>
      <MyNumberLink/>
      <Section>
        <h2 className="text-xl f mb-2">払戻口座登録</h2>
      </Section>
      <Section className="bg-secondary-bg py-2">
        <p>※不正出金防止のため、必ず投資家様名義の口座をご登録ください。</p>
      </Section>
      <Section>
        <InputField
          name='bank_name'
          type='text'
          onChange={handleChange}
          value={state.bank_name}
          label="銀行名"
          showRequired
          width='w-full'
          errors={errors}
          required
        />
        <InputField
          name='branch_name'
          type='text'
          onChange={handleChange}
          value={state.branch_name}
          label="支店名"
          showRequired
          width='w-full'
          errors={errors}
          required
        />
        <RadioButton
          options={accountTypeOptions}
          labelKey='label'
          valueKey='value'
          value={state.account_type}
          label="口座種別"
          onChange={handleChange}
          name='sex'
          checked
          showRequired
        />
        <InputField
          name='account_number'
          type='text'
          onChange={handleChange}
          value={state.account_number}
          label="口座番号"
          showRequired
          width='w-full'
          errors={errors}
          required
          suggestion="※半角数字、ハイフン(-)なしで入力してください。"
        />
        <InputField
          name='account_holder'
          type='text'
          onChange={handleChange}
          value={state.account_holder}
          label="口座名義（カナ)"
          showRequired
          width='w-full'
          errors={errors}
          required
          suggestion="※カタカナで入力してください。"
        />
      </Section>
      <Section className="flex max-[400px]:flex-col flex-row justify-center items-center gap-5 mt-8 mb-10">
        <Link
          to='/'
          className='flex flex-col items-center justify-center rounded border-4 px-2 text-sm lg:text-lg font-bold text-secondary-text border-secondary-text py-2.5 bg-white h-[60px] w-[300px] max-[300px]:w-full'>
          マイページ<span className="max-[460px]:block hidden"></span>トップに戻る
        </Link>
        <button
          type="submit"
          onClick={handleCompletion}
          className='flex items-center justify-center rounded border-4 px-2 lg:text-lg font-bold text-white bg-primary-main border-primary-main py-2.5 h-[60px] w-[300px] max-[300px]:w-full'>
          登録する
        </button>
      </Section>

    </React.Fragment>
  )
}

export default BankAccount;