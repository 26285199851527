import React from "react";
import Image from "../../assets/noimage.jpg";
import {ProgressBar} from "./ProgressBar";
import {Section} from "../../components/layouts/section";
import {RecruitingProps} from "./Recruitng";

export const FundData: React.FC<RecruitingProps> = ({fund}) => {
    const filteredRecruiting = fund.recruiting?.filter((val) =>
        val.is_finished || val.id === fund.current_recruitment?.id
    );
    return (
        <Section className="my-8">
            <h2 className="text-xl mb-4">募集中ファンド</h2>
            <div className="grid grid-cols-3 gap-8">
                <figure className="col-span-1 h-[150px] overflow-hidden border">
                    <img className="w-full h-full object-cover" src={Image} alt=""/>
                </figure>
                <div className="col-span-2 flex flex-col justify-between ">
                    <div>
                        <div className="text-primary-dark text-sm pb-2">
                            <span
                                className="bg-secondary-dark text-primary-dark py-1.5 px-3 mr-2">{fund.status}</span>
                            <time>2023年3月31日まで</time>
                        </div>
                        <h3 className="text-xl sm:text-2xl text-primary-dark font-bold">{fund.name}</h3>
                    </div>
                    <div>
                        {/* // TODO :progress bar */}
                        {filteredRecruiting?.map((val, index) => (
                            <ProgressBar
                                key={index}
                                progress={val.name === 'a' ? 100 - ((val.units_remainder / val.units) * 100) : +((val.units_remainder / val.units) * 100).toFixed(2)}
                                color={val.is_finished ? 'bg-primary-main' : 'bg-primary-bg'}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </Section>
    )
}