import React, {useState} from "react";
import CampaignBanner from "../../components/ui/CampaignBanner";
import MyNumberLink from "../../components/ui/MyNumberLink";
import {Section} from "../../components/layouts/section";
import {DocumentHeader, DocumentsList, DocumentTabLayout} from "./DocumentList";


const Documents: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<string>(''); // State to track the selected option

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value); // Update the selected option when the dropdown value changes
  };

  return (
    <React.Fragment>
      <CampaignBanner/>
      <MyNumberLink/>
      <Section className="mt-10 mb-5">
        <div className="border-b-4 flex flex-col justify-between pb-3 w-full">
          <h2 className="text-2xl text-left mb-1">取引履歴</h2>
          <select
            className="border py-2 px-3 rounded w-full bg-white"
            value={selectedOption}
            onChange={handleOptionChange}
          >
            <option value="">選択してください</option>
            <option value="投資家関連書面">投資家関連書面</option>
            <option value="ファンド関連書面">ファンド関連書面</option>
            <option value="その他書面">その他書面</option>
          </select>
        </div>
      </Section>

      {selectedOption === '投資家関連書面' &&
          <Section className="my-2">
              <DocumentsList
                  className="border-b py-4"/>
              <DocumentsList
                  className="border-b py-4"/>
              <DocumentsList
                  className="border-b py-4"/>
          </Section>
      }
      {selectedOption === 'ファンド関連書面' &&
          <Section>
              <DocumentHeader title="ファンド関連書面の内容">
                  <DocumentsList
                      className="border-t border-dashed py-4"/>
                  <DocumentsList
                      className="border-t border-dashed py-4"/>
                  <DocumentsList
                      className="border-t border-b border-dashed py-4"/>
              </DocumentHeader>
              <DocumentHeader title="ファンド関連書面の内容">
                  <DocumentsList
                      className="border-b py-3"/>
              </DocumentHeader>
          </Section>}
      {selectedOption === 'その他書面' &&
          <Section>
              <DocumentTabLayout tabs={['年間取引報告書', '決算報告書']}>
                  <div>
                      <DocumentsList
                          className="border-b py-3"/>
                  </div>
                  <div>
                      <DocumentsList
                          className="border-b py-4"/>
                  </div>
              </DocumentTabLayout>
          </Section>}
    </React.Fragment>
  );
};

export default Documents;
