import React, {useEffect, useState} from 'react';

import CampaignBanner from "../../components/ui/CampaignBanner";
import MyNumberLink from "../../components/ui/MyNumberLink";
import {Section} from "../../components/layouts/section";
import {InputField} from "../../components/ui/form/InputField";
import {
  investmentFundsOptions,
  MemberInformationType,
  occupationOptions,
  yearsOfInvestOptions
} from "../../store/account";
import {useAccount} from "./accountDummy";
import {ErrorsType} from "../../components/ui/form/Error";
import {Link, useNavigate} from "react-router-dom";
import {RadioButton} from "../../components/ui/form/RadioButton";
import {InputRow} from "../../components/layouts/InputRow";
import SelectField from "../../components/ui/form/SelectField";


const EditMemberInformation: React.FC = () => {
  const navigate = useNavigate();
  const {memberInformation} = useAccount();
  const [state, setState] = useState<MemberInformationType>(memberInformation);
  const [errors, setErrors] = useState<ErrorsType>();
  const handleCompletion = () => {
    navigate('/account');
  };
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const {name, value} = e.target;
    setState((prev) => ({...prev, [name]: value}));
  };
  return (
    <React.Fragment>
      <CampaignBanner/>
      <MyNumberLink/>
      <Section className="mt-10">
        <h2 className="text-xl mb-4">金融資産の変更</h2>
      </Section>
      <Section className="bg-secondary-bg py-2">
        // TODO: p tag text
        <p className="text-base leading-10">※お名前・住所・生年月日を変更する場合、再度本人確認が必要になります。</p>
      </Section>
      <Section>
        <InputRow title="ご職業">
          <SelectField
            options={occupationOptions}
            valueKey='value'
            labelKey='label'
            value={state.occupation || ''}
            width='max-[400px]:w-full lg:w-1/2'
            required
            name='occupation'
            errors={errors}
            onChange={handleChange}
          />
        </InputRow>
        <InputRow title="勤務先">
          <InputField
            onChange={handleChange}
            name='workplace'
            value={state.workplace || ''}
            width='max-[400px]:w-full lg:w-1/2'
            errors={errors}
            required
          />
        </InputRow>
        <InputRow title="年収">
          <InputField
            onChange={handleChange}
            name='annual_income'
            value={state.annual_income || ''}
            width='max-[400px]:w-full lg:w-1/2 '
            errors={errors}
            iconRight="万円"
            required
          />
        </InputRow>
        <InputRow title="金融資産">
          <InputField
            onChange={handleChange}
            name='financial_assets'
            value={state.financial_assets || ''}
            width='max-[400px]:w-full lg:w-1/2 '
            errors={errors}
            iconRight="万円"
            required
          />
        </InputRow>
        <InputRow title="投資年数">
          <SelectField
            options={yearsOfInvestOptions}
            valueKey='value'
            labelKey='label'
            name='investment_year'
            width='max-[400px]:w-full lg:w-1/2'
            value={state.investment_year}
            errors={errors}
            onChange={handleChange}
          />
        </InputRow>
        <InputRow title="投資資金">
          <RadioButton
            options={investmentFundsOptions}
            labelKey='label'
            valueKey='value'
            value={state.investment_funds}
            onChange={handleChange}
            name='investment_funds'
            checked
          />
        </InputRow>
      </Section>
      <Section className="bg-secondary-bg py-4 mb-5">
        <p>上記の内容で変更しますか？</p>
      </Section>
      <Section className="flex max-[400px]:flex-col flex-row justify-center items-center gap-5 mt-8 mb-10">
        <Link
          to='/account'
          className='flex items-center justify-center rounded border-4 px-2 text-lg font-bold text-secondary-text border-secondary-text py-2.5 bg-white h-[50px] w-[215px] max-[215px]:w-full'>
          戻る
        </Link>
        <button
          type="submit"
          onClick={handleCompletion}
          className='flex items-center justify-center rounded border-4 px-2 text-lg font-bold text-white bg-primary-main border-primary-main py-2.5 h-[50px] w-[215px] max-[215px]:w-full'>
          変更する
        </button>
      </Section>
    </React.Fragment>
  )
}

export default EditMemberInformation;