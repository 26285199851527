import React from "react";
import {RegistrationStepTwo} from "./StepTwo";
import Stepper from "../../components/ui/Stepper/Stepper";
import {RegistrationStepOne} from "./StepOne";
import {RegistrationStepThree} from "./StepThree";
import {Section} from "../../components/layouts/section";
import logo from '../../assets/logo.png';

const UserRegistration: React.FC = () => {
  return (
    <main className="flex flex-col max-[820px]:w-full min-h-screen w-[820px] mx-auto bg-white pb-5 mb-20">
      <div className="flex items-center justify-center mb-14 mt-5">
        <figure className="w-[150px]">
          <img src={logo} alt=""/>
        </figure>
      </div>
      <Stepper>
        <Stepper.Steps>
          <Stepper.Step id='1' name='会員登録'>
            <RegistrationStepOne/>
          </Stepper.Step>
          <Stepper.Step id='2' name='投資家登録'>
            <RegistrationStepTwo/>
          </Stepper.Step>
          <Stepper.Step id='3' name='本人確認'>
            <RegistrationStepThree/>
          </Stepper.Step>
        </Stepper.Steps>
      </Stepper>
      <Section className="mt-20">
        <p className="text-xs text-secondary-text">(c) 2023 MitsubachiFund All Rights Reserved. </p>
      </Section>
    </main>
  )
}
export default UserRegistration;