import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Section} from "../../components/layouts/section";
import CampaignBanner from "../../components/ui/CampaignBanner";
import MyNumberLink from "../../components/ui/MyNumberLink";
import {WithdrawHistoryType} from "../../store/transaction";

const WithDrawHistory: React.FC = () => {
  const [modalState, setModalState] = useState<{
    isModalOpen: boolean;
    selectedData: WithdrawHistoryType | null;
  }>({isModalOpen: false, selectedData: null,});
  const openModal = (data: WithdrawHistoryType) => {
    setModalState({
      isModalOpen: true,
      selectedData: data,
    });
  };

  const closeModal = () => {
    setModalState({
      isModalOpen: false,
      selectedData: null,
    });
  };

  const handelSubmit = () => {
    console.log("Submit success");
    closeModal();
  };

  const [dataHistory, setDataHistory] = useState<WithdrawHistoryType[]>([
    {
      withdraw_date: "2023年1月1日",
      amount: "22,000",
      status: "申請キャンセル",
    },
    {
      withdraw_date: "2023年1月1日",
      amount: "200",
      status: "キャンセル済",
    },
  ]);

  return (
    <React.Fragment>
      <CampaignBanner/>
      <MyNumberLink/>
      <Section>
        <h2 className="text-xl f mb-2">払戻申請履歴</h2>
        <table className="w-full bg-white">
          <thead>
          <tr className={`border border-b-0 last:border-b items-stretch`}>
            <th
              className={`border-r font-normal sm:border-b-0 px-2 text-start flex-1 sm:w-52 py-3 bg-secondary-bg text-sm sm:text-base`}>申請日時/金額
            </th>
            <th
              className={`border-b font-normal sm:border-b-0 px-2 text-start w-32 py-3 bg-secondary-bg text-sm sm:text-base`}>ステータス
            </th>
          </tr>
          </thead>
          <tbody>
          {dataHistory.map((data, index) => (
            <tr key={index} className={`border border-b-0 last:border-b items-stretch`}>
              <td className='flex-1 px-2 text-base py-3 font-normal border-r'>
                <time className="text-secondary-text text-sm">{data.withdraw_date}</time>
                <p className="text-xl font-bold">{data.amount} 円</p>
              </td>
              <td className="w-32 px-2 text-base py-3 font-normal">
                {data.status === "申請キャンセル" ? (
                  <button
                    className=" flex items-center justify-center rounded border-4 px-2 text-lg border-accent-main py-2 bg-accent-main w-full text-white"
                    onClick={() => openModal(data)}
                  >
                    {data.status}
                  </button>
                ) : (
                  <p className="text-center text-secondary-text font-bold">
                    {data.status}
                  </p>
                )}
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </Section>

      {modalState.isModalOpen && modalState.selectedData && (
        <div className="fixed inset-0 bg-primary-bg bg-opacity-50 flex items-center justify-center">
          <div className="w-[450px] max-[450px]:w-full bg-white max-[350px]:p-5 p-10">
            <h3 className="font-bold max-[450px]:text-xl sm:text-2xl">払戻申請キャンセル</h3>
            <div
              className="flex flex-col justify-center items-center gap-y-5 max-[350px]:px-0 max-[450px]:px-5 px-10 mt-8 mb-10">
              <p className="max-[450px]:text-base text-lg"><strong>「{modalState.selectedData.amount}」</strong>の申請を
                キャンセルしますか？</p>
              <button
                type="button"
                onClick={closeModal}
                className='flex items-center justify-center rounded border-4 px-2 text-lg text-secondary-text border-secondary-text py-2.5 bg-white h-[50px] w-full'>
                キャンセルしない
              </button>
              <button
                type="submit"
                onClick={handelSubmit}
                className='flex items-center justify-center rounded border-4 px-2 text-lg text-white bg-secondary-text border-secondary-text py-2.5 h-[50px] w-full'>
                はい
              </button>
            </div>
          </div>
        </div>

      )}
    </React.Fragment>
  );
};

export default WithDrawHistory;
