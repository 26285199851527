import React from "react";
import {Section} from "../../components/layouts/section";
import {Link} from "react-router-dom";

const WithDrawCompleted: React.FC = () => {
  return (
    <div>
      <Section className="mt-10">
        <h2 className="text-xl mb-4">払戻申請が完了しました。</h2>
      </Section>
      <Section className="bg-secondary-bg py-3">
        <p className="text-base leading-10">
          払戻申請をキャンセルする場合は
          <Link to="" className="underline underline-offset-4 text-primary-main font-bold">こちら</Link>
          からお願いいたします。
        </p>
      </Section>
      <Section>
        <h3>《払戻についての注意事項》</h3>
        <ul className="square leading-7 mt-2 tracking-widest">
          <li>当日の23時59分までに預託金の払い戻し申請をいただいてから、当日を含む5営業日以内に、ご指定の口座にお振込みいたします。</li>
          <li>ご依頼いただいた金額から払戻手数料（一律250円）を差し引いた金額を送金させていただきます。</li>
          <li>抽選で落選した投資家様、先着順ファンドで出資できなかった投資家様に関する払い戻しは、抽選結果発表日（先着順の場合、完売日）から1週間以内・申込額以上限り手数料無料といたします。</li>
        </ul>
      </Section>
    </div>
  )
}
export default WithDrawCompleted;