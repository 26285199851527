import React, {useRef, useState} from 'react';
import {ChoiceOptionType} from "../../store";
import {Section} from "../../components/layouts/section";
import Images from '../../assets/noimage.jpg'
import {FaTimes, FaRegCircle, FaChevronCircleRight} from "react-icons/fa";
import Select from "../../components/ui/form/SelectField";
import {MyNumberDataType, MyNumberImageType, MyNumberType} from "../../store/account";
import FileInput from "../../components/ui/form/FileInput";
import {Link} from "react-router-dom";

export const myNumberTypeOptions: ChoiceOptionType[] = [
  {value: '0', label: '個人番号カード（表・裏）'},
  {value: '1', label: '個人番号通知カード（表・裏）'},
  {value: '2', label: '住民票の写し'},
];
const MyNumberRegistration: React.FC = () => {
  const [type, setType] = useState<MyNumberType>('0');
  const buttonRef = useRef<HTMLButtonElement>(null!);
  const [images, setImages] = useState<MyNumberImageType>({front: null, back: null, resident: null});

  const handleSubmit = () => {
    let data: MyNumberDataType;
    buttonRef.current.disabled = true;
    if (type === '2') {
      data = {
        status: 'pending',
        'mynumber_images': [
          {'image_type': 'resident', 'image_data': images.resident},
        ],
      };
    } else {
      data = {
        status: 'pending',
        'mynumber_images': [
          {'image_type': 'front', 'image_data': images.front},
          {'image_type': 'back', 'image_data': images.back},
        ],
      };
    }
    // userService.uploadMynumber(data)
    //   .then(() => {
    //     setCompleted(true);
    //   })
    //   .finally(() => {
    //     buttonRef.current.disabled = false;
    //   });
  };
  return (
    <div className="h-full">
      <Section>
        <h2 className="text-xl mb-4">マイナンバー登録</h2>
        <p className="text-base leading-7 ">当社は各種法令で定められた手続きとして、お客様から源泉徴収を行なった金額を税務署に提出しており、その際に必要となる<b>「法定調書へのマイナンバー記載</b>」を目的としてマイナンバーのご登録をお願いしております。
        </p>
      </Section>
      <Section className="bg-secondary-bg pt-5 pb-5">
        <h3 className="text-xl mb-4">《注意事項》</h3>
        <ul className="dimond leading-8 tracking-widest">
          <li>個人番号カードまたは個人番号通知カードをお持ちでない場合は、現住所の確認できる本人確認書類＋マイナンバーが記載された住民票の写しが必要となります。</li>
          <li>ファイルサイズは、1枚あたり10MBまでです。</li>
          <li>ファイル形式は、jpeg / png / gifのいずれかです。</li>
          <li>文字が読める画像を撮影してください。</li>
          <li>四隅が写るように写真を撮影してください。</li>
          <li>文字が隠れていないものを撮影してください。</li>
        </ul>
      </Section>
      <Section className="bg-white">
        <ul className="flex flex-wrap justify-between flex-row">
          <li className="w-[48%] mt-5">
            <figure className="h-[112px] sm:h-[150px] overflow-hidden border">
              <img className="w-full object-cover h-full" src={Images} alt=""/>
            </figure>
            <p className="flex items-center text-center justify-center my-4 text-sm"><FaRegCircle
              className="text-red-500"/>問題なし</p>
          </li>
          <li className="w-[48%] mt-5">
            <figure className="h-[112px] sm:h-[150px] overflow-hidden border">
              <img className="w-full object-cover h-full" src={Images} alt=""/>
            </figure>
            <p className="flex items-center text-center justify-center my-4 text-sm"><FaTimes
              className="text-blue-400"/> ぼやけている</p>
          </li>
          <li className="w-[48%] mt-5">
            <figure className="h-[112px] sm:h-[150px] overflow-hidden border">
              <img className="w-full object-cover h-full" src={Images} alt=""/>
            </figure>
            <p className="flex items-center text-center justify-center my-4 text-sm"><FaTimes
              className="text-blue-400"/> 四隅が見切れている</p>
          </li>
          <li className="w-[48%] mt-5">
            <figure className="h-[112px] sm:h-[150px] overflow-hidden border">
              <img className="w-full object-cover h-full" src={Images} alt=""/>
            </figure>
            <p className="flex items-center text-center justify-center my-4 text-sm"><FaTimes
              className="text-blue-400"/>文字が隠れている</p>
          </li>
        </ul>
      </Section>
      <Section>
        <div className="border rounded px-6 py-8">
          <h3 className="mb-5">マイナンバー確認書類の種類を選択してください。</h3>
          <Select
            label=""
            labelClass="text-xl"
            labelKey='label'
            valueKey='value'
            name='myNumberType'
            required={false}
            value={type}
            options={myNumberTypeOptions}
            onChange={(e) => {
              setType(e.target.value as MyNumberType);
              setImages({front: null, back: null, resident: null});
            }}
            className="mb-8"
            width="w-full"
          />
          {
            type === '2' ?
              <div
                className='mt-8 mb-5 flex flex-wrap items-center justify-center gap-3 px-2 sm:mt-10 sm:mb-10 sm:gap-5 lg:gap-10'>
                <FileInput value={images.resident} onChange={(image) => {
                  setImages((prev) => ({...prev, resident: image}));
                }} text='表面の撮影画像を添付する' label='ファイルを選択' name='resident'/>
              </div>
              :
              <div>
                <div className='mt-6 flex flex-wrap items-center justify-center gap-3 px-2 sm:gap-5 lg:gap-10'>
                  <FileInput value={images.resident} onChange={(image) => {
                    setImages((prev) => ({...prev, resident: image}));
                  }} text='表面' label='ファイルを選択' name='front'/>
                </div>
                <div className='mt-6 flex flex-wrap items-center justify-center gap-3 px-2 sm:gap-5 lg:gap-10'>
                  <FileInput value={images.resident} onChange={(image) => {
                    setImages((prev) => ({...prev, resident: image}));
                  }} text='裏面' label='ファイルを選択' name='back'/>
                </div>
              </div>
          }
        </div>
        <div className='mt-8 flex flex-wrap items-center justify-center pb-10'>
          <Link to={'/account'}
            // disabled={!(!!(images.front && images.back) || !!(type === '2' && images.resident))}
                className={`${(!!(images.front && images.back) || !!(type === '2' && images.resident)) ? 'bg-primary-light' : 'bg-primary-main'} w-[450px] font-bold flex justify-center items-center rounded-md px-2 text-white py-4 sm:text-lg h-[66px] max-[500px]:w-full`}>
            マイナンバーを登録する
          </Link>
        </div>
      </Section>
    </div>
  )
}
export default MyNumberRegistration;
