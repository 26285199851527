import React, { useState, useEffect } from "react";
import logo from '../../assets/logo.png';
import { Link, Outlet } from "react-router-dom";
import { FaRegBell} from "react-icons/fa";
import Notification from "./Notification";

export const Header: React.FC = () => {
    const [isNotificationModalOpen, setNotificationModalOpen] = useState(false);

    useEffect(() => {
        if (isNotificationModalOpen) {
            document.body.style.overflow = "hidden"; // Disable scrolling
        } else {
            document.body.style.overflow = "auto"; // Enable scrolling
        }
    }, [isNotificationModalOpen]);

    const handleBellClick = () => {
        setNotificationModalOpen(true);
    };

    const handleCloseModal = () => {
        setNotificationModalOpen(false);
    };

    const notifications = [
        {
            date: "2023年4月1日 12:00",
            title: "みつばち5号ファンドへの出資申込を受け付けました。",
            content: "この度は「みつばち5号ファンド」に出資申込みいただき誠にありがとうございます。 抽選結果の発表は以下の通りです。\nこの度は「みつばち5号ファンド」に出資申込みいただき誠にありがとうございます。 抽選結果の発表は以下の通りです。"
        },
    ];
    return(
        <main className="max-[820px]:w-full min-h-screen w-[820px] mx-auto bg-white pb-5 mb-20">
           <header className="z-50 top-0 left-0 right-0 py-2 px-6 bg-white items-center justify-between overflow-hidden drop-shadow-md">
                <div className="flex items-center justify-between">
                    <Link className="h-full" to={"/"}>
                        <img className="h-16" src={logo} alt="logo" />
                    </Link>
                    <div className="relative bell" onClick={handleBellClick}>
                        <FaRegBell className="h-8 w-8 fill-[#EEEEEE]"/>
                        <div className="absolute bg-accent-main h-5 w-5 text-center text-white rounded-full flex items-center justify-center text-[10px] -top-1 right-0">
                            3<span className="absolute text-[8px] -top-0.5 right-1">+</span>
                        </div>
                    </div>
                </div>
           </header>
            <Outlet />
            {isNotificationModalOpen && (
                <div className="fixed inset-0 bg-primary-bg bg-opacity-70 flex items-center justify-center">
                    <Notification notifications={notifications} onClose={handleCloseModal} />
                </div>
            )}
        </main>
    )
}
