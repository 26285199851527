import React from 'react';
import {Route, Routes} from "react-router-dom";
import Home from './pages/home';
import {Header} from './components/ui/Header';
import {MenuFooter} from './components/ui/Footer';
import MyNumberRegistration from "./pages/account/myNumberRegistration";
import Account from "./pages/account/account";
import EditEmail from "./pages/account/editEmail";
import EditPassword from "./pages/account/editPassword";
import EditPersonalInformation from "./pages/account/editPersonalInformation";
import EditMemberInformation from "./pages/account/editMemberInformation";
import Deposit from "./pages/transaction/deposite";
import Transaction from "./pages/transaction";
import WithDraw from "./pages/transaction/withDraw";
import WithDrawHistory from "./pages/transaction/WithDrawHistory";
import BankAccount from "./pages/account/bankAccount";
import Fund from "./pages/fund";
import FundApplication from "./pages/fund/application";
import UserRegistration from "./pages/registration";
import {StepperProvider} from "./contexts/stepper";
import News from "./pages/news";
import NewsDetails from "./pages/news/details";
import InvestmentStatusDetails from "./pages/investmentStatus/details";
import Menu from "./pages/menu";
import Index from "./pages/tradingHistory";
import Documents from "./pages/Document";
import MembershipRank from "./pages/membershipRank";

function App() {
	return (
		<StepperProvider>
			<Routes>
				<Route path="*">
					<Route path="user-registration" element={<UserRegistration/>}/>
					<Route element={<Header/>}>
						<Route element={<MenuFooter/>}>
							<Route index={true} element={<Home/>}/>
							<Route path="menu" element={<Menu/>}/>
							<Route path="account" element={<Account/>}/>
							<Route path="account/edit/email" element={<EditEmail/>}/>
							<Route path="account/edit/password" element={<EditPassword/>}/>
							<Route path="account/edit/personal-information" element={<EditPersonalInformation/>}/>
							<Route path="account/edit/member-information" element={<EditMemberInformation/>}/>
							<Route path="mynumber-registration" element={<MyNumberRegistration/>}/>
							<Route path="transaction" element={<Transaction/>}/>
							<Route path="deposit" element={<Deposit/>}/>
							<Route path="withdraw" element={<WithDraw/>}/>
							<Route path="withDraw-history" element={<WithDrawHistory/>}/>
							<Route path="bank-account" element={<BankAccount/>}/>
							<Route path="fund" element={<Fund/>}/>
							<Route path="application/:slug" element={<FundApplication/>}/>
							<Route path="news" element={<News/>}/>
							<Route path="news/:slug" element={<NewsDetails/>}/>
							<Route path="investment-status/:slug" element={<InvestmentStatusDetails/>}/>
							<Route path="trading-history" element={<Index/>}/>
							<Route path="document" element={<Documents/>}/>
						</Route>
					</Route>
				</Route>
			</Routes>
		</StepperProvider>
	);
}

export default App;
