import {
    BankAccountType, InvestorRegistrationType,
    LoginInformationType,
    MemberInformationType,
    PersonalInformationType
} from "../../store/account";

const dummyPersonalInfo: PersonalInformationType = {
     last_name: 'Doe',
     first_name: 'Doe',
     last_name_kana: 'Doe',
     first_name_kana: 'Doe',
     sex: '男',
     phone_number: '000000000',
     postal_code: '000-0000',
     prefecture: 'Doe',
     city_ward: 'Doe',
     block_name: 'Doe',
     address4: 'Doe',
     birth_date: 'Doe',
}

 const dummyBankAccount: BankAccountType = {
     account_holder: 'ヤマダ　タロウ',
     account_number: '383838',
     account_type: '普通',
     bank_name: 'みつばち銀行',
     branch_name: 'はちみつ支店',
}
 const dummyMemberInformation: MemberInformationType = {
    occupation: 'Doe',
    workplace: 'Doe',
    annual_income: 30,
    financial_assets: 4000,
    investment_year: 'Doe',
    investment_funds: '余剰金',
}
 const dummyLoginInformation: LoginInformationType = {
    email:'example@gmail.com',
}
const dummyInvestorRegistrationType:InvestorRegistrationType= {
    last_name: 'Doe',
     first_name: 'Doe',
     last_name_kana: 'Doe',
     first_name_kana: 'Doe',
     sex: '男',
     phone_number: '000000000',
     postal_code: '000-0000',
     prefecture: 'Doe',
     city_ward: 'Doe',
     block_name: 'Doe',
     address4: 'Doe',
     birth_date: 'Doe',
    occupation: 'Doe',
    workplace: 'Doe',
    annual_income: 30,
    financial_assets: 4000,
    investment_year: 'Doe',
    investment_funds: '余剰金',
    investment_policy:'短期',
    asset_type: [],
   investment_experience: [],
}

export const dummyUser = {
    id: 1,
    name: "John Doe",
    deposit: 5000000,
    point:5000,
};

export function useAccount() {
  return {
      loginInformation: dummyLoginInformation,
      personalInformation: dummyPersonalInfo,
      memberInformation: dummyMemberInformation,
      investorRegistrationType: dummyInvestorRegistrationType,
      bankAccount: dummyBankAccount,
  };
}