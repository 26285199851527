import React from "react";
import {Section} from "../../components/layouts/section";
import {TableData, TableHeader} from "../../components/layouts/tableLayout";
import {FundType} from "../../store/fund";
import {FaChevronCircleRight} from "react-icons/fa";
import {Link} from "react-router-dom";
import {FundData} from "./FundData";

export interface RecruitingProps{
  fund: FundType;
}
export const Recruiting: React.FC<RecruitingProps> = ({ fund }) =>{
    return(
    <React.Fragment>
        <FundData fund={fund}/>
        <Section>
            <TableHeader>
                <TableData name="想定利回り">
                    {fund.capital_interest && <p><span className="text-accent-main font-bold">{fund.capital_interest}% </span> (税引前）</p> }
                </TableData>
                <TableData name="募集金額">
                    <p>{fund.amount_raised}万円</p>
                </TableData>
                <TableData name="出資金額">
                    <p>{fund.total_investment}万円</p>
                </TableData>
                <TableData name="募集口数">
                    {fund.number_of_units}口
                </TableData>
                <TableData name="出資額">
                    <p>{fund.unit}</p>
                    <p className="text-secondary-text text-xs">*{fund.min_num_unit}口から{fund.max_num_units}口まで出資可能</p>
                </TableData>
                <TableData name="想定運用期間">
                    <p>{fund.duration}{fund.duration_type}</p>
                    <p className="text-secondary-text text-xs">{fund.operation_start}{fund.operation_end}</p>
                </TableData>
                <TableData name="総分配回数">
                    {fund.total_distributions &&
                        <div>
                        <p>{fund.total_distributions}</p>
                        <p className="text-secondary-text text-xs">*償還時配当</p>
                        </div>
                    }
                </TableData>
                <TableData name="取引種別">
                    <p>{fund.transaction_type}</p>
                </TableData>
                <TableData name="抽選結果発表">
                    -
                </TableData>
            </TableHeader>
            <div className="my-5">
                {/* TODO : link redirect */}
                <Link to={`https://mitsubachi.com/fund_list/${fund.slug}/`} className="sm:text-lg justify-end items-center text-primary-dark tracking-widest flex mt-2">
                    ファンド詳細を確認
                    <span><FaChevronCircleRight className="fill-primary-main h-6 w-6"/></span>
                </Link>
            </div>
            <div className='mt-12 flex flex-col items-center justify-center'>
                {fund.status === '募集中' ? (
                <Link
                    to={`/application/${fund.slug}`}
                  type='submit'
                  className='flex items-center tracking-widest justify-center rounded border-4 px-2 text-base sm:text-lg font-bold text-white bg-accent-main border-accent-main py-3 sm:py-3.5 w-[300px] max-[310px]:w-full'>
                  出資する
                </Link> ) : null}
            </div>
        </Section>
    </React.Fragment>
    )
}
