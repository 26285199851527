import React from "react";
import { FaTimes } from "react-icons/fa";


interface NotificationProps {
    notifications: Array<{
        date?: string;
        title?: string;
        content?: string;
    }>;
    onClose: () => void;
}

const Notification: React.FC<NotificationProps> = ({ notifications, onClose }) => {
    return (
        <div className="w-[355px] max-h-[611px] mx-auto bg-white relative">
            <div className="flex px-3 pt-4 pb-3 flex-row justify-between items-center align-center">
                <h2 className="text-lg">通知</h2>
                <button className="text-accent-bg" onClick={onClose}>
                    <FaTimes className="h-6 w-6"/>
                </button>
            </div>
            <ul className="overflow-y-scroll max-h-[500px] no-scrollbar">
                {notifications.map((notification, index) => (
                    <li className="border-t-2 border-accent-bg mb-5">
                    <div className="border-b border-accent-bg flex py-2 px-3 items-center flex-row gap-3">
                        <figure className="h-9 w-9 bg-slate-300"></figure>
                        <div>
                            <time className="text-[10px]">{notification.date}</time>
                            <h3 className="text-base">{notification.title}</h3>
                        </div>
                    </div>
                    <p className="ml-9 text-sm py-3 px-3">
                        {notification.content}
                    </p> 
                </li>
                ))}
            </ul>
        </div>
    );
};

export default Notification;