import React from "react";
import {Section} from "../../../components/layouts/section";
import {ApplicationStep} from "./ApplicationStep";
import ContactBanner from "../../../components/layouts/ContactBanner";
import {Link} from "react-router-dom";


export const AdvanceNotice: React.FC = () => {
  return (
    <Section className="pt-10">
      <h3 className="text-2xl mb-2">事前入金のご案内</h3>
      <p className="text-base leading-7">みつばちファンドでは<strong>事前入金制度</strong>を導入しております。出資希望額をデポジット口座へご入金の上、出資申込にお進みください。
      </p>

      <h3 className="mt-8 mb-1 text-xl">《注意事項》</h3>
      <ul className="square">
        <li className="text-primary-text">振込手数料はお客様負担となります。</li>
        <li>ご入金した出資金は数分〜24時間程度でマイ ページに反映されます。</li>
      </ul>
      <div className="flex items-center justify-center my-10">
        <Link
          to='/'
          className='flex items-center justify-center rounded border-4 px-2 lg:text-lg font-bold text-accent-main bg-white border-accent-main py-2.5 h-[60px] w-[300px] max-[300px]:w-full'>
          デポジット口座へ入金
        </Link>
      </div>
    </Section>
  )
}