import React from "react";
import {Link} from "react-router-dom";

export type TableLayoutProp = {
    name?: string
    title?: string;
    className?: string;
    link?: string;
    border?:string;
    children?: React.ReactNode
}
export const TableData: React.FC<TableLayoutProp> = ({name,border, children}) => {
    return (
        <tr
            className={`${border} border border-b-0 flex flex-row items-start last:border-b items-stretch`}>
            <th
                className={`${border} border-r border-b font-normal sm:border-b-0 px-2 text-start w-32 sm:w-52 py-3 bg-secondary-bg text-sm sm:text-base`}>{name}</th>
            <td className='flex-1 px-2 text-base py-3 font-normal leading-normal'>
                {children}
            </td>
        </tr>
    );
};
export const TableHeader: React.FC<TableLayoutProp> = ({title,link='', className, children}) => {
    return (
        <React.Fragment>
            <div className={`${className} flex justify-between items-center`}>
                <h2 className='py-4 text-2xl font-bold text-primary-text'>{title}</h2>
                { link &&
                    <Link to={link}
                          className='flex items-center justify-center rounded border px-2 text-sm font-bold text-primary-dark border-primary-main h-[35px] text-base w-[80px] rounded max-[90px]:w-full'>
                        変更
                    </Link>
                }
            </div>
            <table className='w-full bg-white'>
                <tbody>
                {children}
                </tbody>
            </table>
        </React.Fragment>
    );
};
