import React, {useRef, useState} from "react";
import CampaignBanner from "../../components/ui/CampaignBanner";
import MyNumberLink from "../../components/ui/MyNumberLink";
import {Section} from "../../components/layouts/section";
import {ErrorList, ErrorsType} from "../../components/ui/form/Error";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import {Link} from "react-router-dom";
import OtpVerification from "./OtpVerification";
import {useAccount} from "./accountDummy";
import {PasswordChangeType} from "../../store/account";
import PasswordChangeCompleted from "./PasswordChangeCompleted";

const EditPassword: React.FC = () => {
  const [state, setState] = useState<PasswordChangeType>({password: '', 'new_password1': '', 'new_password2': ''});
  const [passwordVisibility, setPasswordVisibility] = useState<boolean[]>([
    false, false, false
  ]);
  const [completed, setCompleted] = useState(false);
  const handleCompletion = () => {
    setCompleted(true);
  };
  const buttonRef = useRef<HTMLButtonElement>(null!);
  const buttonRef2 = useRef<HTMLButtonElement>(null!);
  const togglePasswordVisibility = (index: number) => {
    setPasswordVisibility(prevVisibility => {
      const newVisibility = [...prevVisibility];
      newVisibility[index] = !newVisibility[index];
      return newVisibility;
    });
  };
  const [errors, setErrors] = useState<ErrorsType>();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setState((prev) => ({...prev, [name]: value}));
  };

  return (
    <React.Fragment>
      <CampaignBanner/>
      <MyNumberLink/>
      {!completed ?
        <div>
          <Section className="mt-10">
            <h2 className="text-xl mb-4">ログインパスワードの変更</h2>
          </Section>
          <Section className="bg-secondary-bg py-2">
            <p className="text-base leading-10">以下項目を全て入力し<strong>「変更する」</strong>ボタンを押してください。
            </p>
          </Section>
          <form method='post' className='mt-12' autoComplete='off'>
            <Section>
              <div className='mt-8'>
                <label className='font-normal text-base'>現在のパスワード</label>
                <div className='relative mt-1.5'>
                  <input
                    type={passwordVisibility[0] ? 'text' : 'password'}
                    name='password'
                    value={state.password}
                    onChange={handleChange}
                    readOnly={true}
                    autoComplete='off'
                    onFocus={(e) => {
                      e.currentTarget.removeAttribute('readonly');
                    }}
                    className='h-12 p-4 border-primary-grey rounded-md border w-full'/>
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                    onClick={() => togglePasswordVisibility(0)}
                  >
                    {passwordVisibility[0] ? (<FaEye/>) : (<FaEyeSlash/>)}
                  </button>
                </div>
                <ErrorList name='password' errors={errors}/>
                <p className='text-xs px-1 text-secondary-text'>※半角英数字で入力してだくさい。</p>
              </div>
              <div className='mt-8'>
                <label className='font-normal text-base'>新しいパスワード</label>
                <div className='relative mt-1.5'>
                  <input
                    type={passwordVisibility[1] ? "text" : "password"}
                    name='new_password1'
                    value={state.new_password1}
                    onChange={handleChange}
                    readOnly={true}
                    autoComplete='off'
                    onFocus={(e) => {
                      e.currentTarget.removeAttribute('readonly');
                    }}
                    className='h-12 p-4 border-primary-grey rounded-md border w-full'/>
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                    onClick={() => togglePasswordVisibility(1)}
                  >
                    {passwordVisibility[1] ? (<FaEye/>) : (<FaEyeSlash/>)}
                  </button>
                </div>
                <ErrorList name='password' errors={errors}/>
                <p className='text-xs px-1 text-secondary-text'>※半角英数字、8〜32文字で入力してください。</p>
              </div>
              <div className='mt-8'>
                <label className='font-normal text-base'>新しいパスワード（確認用）</label>
                <div className='relative mt-1.5'>
                  <input
                    type={passwordVisibility[2] ? "text" : "password"}
                    name='new_password2'
                    value={state.new_password2}
                    onChange={handleChange}
                    readOnly={true}
                    autoComplete='off'
                    onFocus={(e) => {
                      e.currentTarget.removeAttribute('readonly');
                    }}
                    className='h-12 p-4 border-primary-grey rounded-md border w-full'/>
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                    onClick={() => togglePasswordVisibility(2)}
                  >
                    {passwordVisibility[2] ? (<FaEye/>) : (<FaEyeSlash/>)}
                  </button>
                </div>
                <ErrorList name='password' errors={errors}/>
                <p className='text-xs px-1 text-secondary-text'>※半角英数字、8〜32文字で入力してください。</p>
              </div>
            </Section>
            <Section className="flex max-[400px]:flex-col flex-row justify-center items-center gap-5 mt-8 mb-10">
              <Link
                to='/account'
                className='flex items-center justify-center rounded border-4 px-2 text-lg font-bold text-secondary-text border-secondary-text py-2.5 bg-white h-[66px] w-[215px] max-[215px]:w-full'>
                戻る
              </Link>
              <button
                onClick={handleCompletion}
                className='flex items-center justify-center rounded border-4 px-2 text-lg font-bold text-white bg-primary-main border-primary-main py-2.5 h-[66px] w-[215px] max-[215px]:w-full'>
                変更する
              </button>
            </Section>
          </form>
        </div> : <PasswordChangeCompleted/>}
    </React.Fragment>
  );
};

export default EditPassword;