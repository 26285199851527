import React from 'react';
import {AgreementType} from "../../../store/fund";
import {FundAgreementCheckLayout} from "../../../components/layouts/FundAgreementCheckLayout";

export type FundPrivacyConformationProps = {
    title?: string;
    className?: string
    color?: string;
    width?: string;
    children?: React.ReactNode;
    setAgreement?: React.Dispatch<React.SetStateAction<AgreementType>>;
    agreement?: AgreementType;
}

export const FundAgreement: React.FC<FundPrivacyConformationProps> = ({setAgreement, agreement}) => {
    const handleAgreement = (type: keyof AgreementType) => {
        if (setAgreement)
            setAgreement((prev) => ({...prev, [type]: true}));
    }
    return(
        <>
            <FundAgreementCheckLayout
                isRead={agreement?.first}
                setAgreement={() => {
                handleAgreement('first')
                }}
                title="個人情報保護方針">
                <div className="text-right text-sm pb-6">
                    <p className="leading-normal">制定年月日　2023年1月1日</p>
                    <p className="leading-normal">最終改正年月日　2023年1月1日</p>
                    <p className="leading-normal">創建住販株式会社</p>
                    <p className="leading-normal">代表取締役　安達 勉</p>
                </div>
                <div className="overflow-y-scroll h-[300px] leading-7">
                    当社は、当社が取り扱う全ての個人情報の保護について、社会的使命を十分に認識し、本人の権利の保護、個人情報に関する法規制等を遵守します。また、以下に示す方針を具現化するための個人情報保護マネジメントシステムを構築し、最新のIT技術の動向、社会的要請の変化、経営環境の変動等を常に認識しながら、その継続的改善に、全社を挙げて取り組むことをここに宣言します。 個人情報は、不動産売買、不動産管理、損害保険代理業、クラウドファンディングに基づく事業業務における当社の正当な事業遂行上並びに従業員の雇用、人事管理上必要な範囲に限定して、取得・利用及び提供をし、特定された利用目的の達成に必要な範囲を超えた個人情報の取扱い（目的外利用）を行いません。また、目的外利用を行わないための措置を講じます。 個人情報保護に関する法令、国が定める指針及びその他の規範を遵守致します。 個人情報の漏えい、滅失、き損などのリスクに対しては、合理的な安全対策を講じて防止すべく事業の実情に合致した経営資源を注入し個人情報セキュリティ体制を継続的に向上させます。また、個人情報保護上、問題があると判断された場合には速やかに是正措置を講じます。 個人情報取扱いに関する苦情及び相談に対しては、迅速かつ誠実に、適切な対応をさせていただきます。 個人情報保護マネジメントシステムは、当社を取り巻く環境の変化を踏まえ、適時・適切に見直してその改善を継続的に推進します。 以上
                </div>
            </FundAgreementCheckLayout>
            <FundAgreementCheckLayout
                isRead={agreement?.second}
                setAgreement={() => {
                handleAgreement('second')
                }}
                title="契約成立前書面"
            >
                <div className="text-right text-sm pb-6">
                    <p className="leading-normal">制定年月日　2023年1月1日</p>
                    <p className="leading-normal">最終改正年月日　2023年1月1日</p>
                    <p className="leading-normal">創建住販株式会社</p>
                    <p className="leading-normal">代表取締役　安達 勉</p>
                </div>
                <div className="overflow-y-scroll h-[300px] leading-7">
                    当社は、当社が取り扱う全ての個人情報の保護について、社会的使命を十分に認識し、本人の権利の保護、個人情報に関する法規制等を遵守します。また、以下に示す方針を具現化するための個人情報保護マネジメントシステムを構築し、最新のIT技術の動向、社会的要請の変化、経営環境の変動等を常に認識しながら、その継続的改善に、全社を挙げて取り組むことをここに宣言します。 個人情報は、不動産売買、不動産管理、損害保険代理業、クラウドファンディングに基づく事業業務における当社の正当な事業遂行上並びに従業員の雇用、人事管理上必要な範囲に限定して、取得・利用及び提供をし、特定された利用目的の達成に必要な範囲を超えた個人情報の取扱い（目的外利用）を行いません。また、目的外利用を行わないための措置を講じます。 個人情報保護に関する法令、国が定める指針及びその他の規範を遵守致します。 個人情報の漏えい、滅失、き損などのリスクに対しては、合理的な安全対策を講じて防止すべく事業の実情に合致した経営資源を注入し個人情報セキュリティ体制を継続的に向上させます。また、個人情報保護上、問題があると判断された場合には速やかに是正措置を講じます。 個人情報取扱いに関する苦情及び相談に対しては、迅速かつ誠実に、適切な対応をさせていただきます。 個人情報保護マネジメントシステムは、当社を取り巻く環境の変化を踏まえ、適時・適切に見直してその改善を継続的に推進します。 以上
                </div>
            </FundAgreementCheckLayout>
            <FundAgreementCheckLayout
                isRead={agreement?.third}
                setAgreement={() => {
                handleAgreement('third')
                }}
                title="電子取引業務に係る重要事項">
                <div className="text-right text-sm pb-6">
                    <p className="leading-normal">制定年月日　2023年1月1日</p>
                    <p className="leading-normal">最終改正年月日　2023年1月1日</p>
                    <p className="leading-normal">創建住販株式会社</p>
                    <p className="leading-normal">代表取締役　安達 勉</p>
                </div>
                <div className="overflow-y-scroll h-[300px] leading-7">
                    <div className="py-2" >
                    当社は、当社が取り扱う全ての個人情報の保護について、社会的使命を十分に認識し、本人の権利の保護、個人情報に関する法規制等を遵守します。また、以下に示す方針を具現化するための個人情報保護マネジメントシステムを構築し、最新のIT技術の動向、社会的要請の変化、経営環境の変動等を常に認識しながら、その継続的改善に、全社を挙げて取り組むことをここに宣言します。 個人情報は、不動産売買、不動産管理、損害保険代理業、クラウドファンディングに基づく事業業務における当社の正当な事業遂行上並びに従業員の雇用、人事管理上必要な範囲に限定して、取得・利用及び提供をし、特定された利用目的の達成に必要な範囲を超えた個人情報の取扱い（目的外利用）を行いません。また、目的外利用を行わないための措置を講じます。 個人情報保護に関する法令、国が定める指針及びその他の規範を遵守致します。 個人情報の漏えい、滅失、き損などのリスクに対しては、合理的な安全対策を講じて防止すべく事業の実情に合致した経営資源を注入し個人情報セキュリティ体制を継続的に向上させます。また、個人情報保護上、問題があると判断された場合には速やかに是正措置を講じます。 個人情報取扱いに関する苦情及び相談に対しては、迅速かつ誠実に、適切な対応をさせていただきます。 個人情報保護マネジメントシステムは、当社を取り巻く環境の変化を踏まえ、適時・適切に見直してその改善を継続的に推進します。 以上
                    </div>
                </div>
            </FundAgreementCheckLayout>
        </>
    )
}
