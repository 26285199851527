import React from "react";
import CampaignBanner from "../../components/ui/CampaignBanner";
import MyNumberLink from "../../components/ui/MyNumberLink";
import {Section} from "../../components/layouts/section";
import { FaChevronCircleRight} from "react-icons/fa";
import {Link} from "react-router-dom";
import {
    BankAccountType,
    LoginInformationType,
    MemberInformationType,
    PersonalInformationType
} from "../../store/account";
import {useAccount} from "./accountDummy";
import {TableData, TableHeader} from "../../components/layouts/tableLayout";

const Account: React.FC = () => {
    const { loginInformation, personalInformation, memberInformation, bankAccount} = useAccount();
    return(
        <React.Fragment>
            <CampaignBanner/>
            <MyNumberLink/>
            <Section>
                <TableHeader title='ログイン情報'>
                    <TableData name="メールアドレス">
                        <div className="flex flex-col">
                            <p>{loginInformation.email}</p>
                            <Link to="/account/edit/email" className="flex items-center gap-1 text-primary-dark text-sm pointer self-end">変更<FaChevronCircleRight className="fill-primary-main"/></Link>
                        </div>
                    </TableData>
                    <TableData name="パスワード">
                        <div className="flex flex-col">
                            <p>*************</p>
                            <Link to="/account/edit/password" className="flex items-center gap-1 text-primary-dark text-sm pointer self-end">変更<FaChevronCircleRight className="fill-primary-main"/></Link>
                        </div>
                    </TableData>
                </TableHeader>
            </Section>

            <Section>
                <TableHeader title='基本情報' link="/account/edit/personal-information">
                    <TableData name="お名前">
                        <p>{personalInformation.last_name ? `${personalInformation.last_name} ${personalInformation.first_name}` : '-'}</p>
                    </TableData>
                    <TableData name="お名前（カナ）">
                        <p>{personalInformation.last_name_kana ? `${personalInformation.last_name_kana} ${personalInformation.first_name_kana}` : '-'}</p>
                    </TableData>
                    <TableData name="性別">
                        <p>{personalInformation.sex || '-'}</p>
                    </TableData>
                    <TableData name="生年月日">
                        <p>{personalInformation.birth_date || '-'}</p>
                    </TableData>
                    <TableData name="電話番号">
                        <p>{personalInformation.phone_number || '-'}</p>
                    </TableData>
                    <TableData name="住所">
                        <p>〒{personalInformation.postal_code || '-'}<br/>{personalInformation.prefecture ? `${personalInformation.city_ward}${personalInformation.block_name}${personalInformation.address4 || ''}` : '-'}</p>
                    </TableData>
                    <TableData name="マイナンバー">
                        <Link to="mynumber-registration" className="underline font-bold text-accent-main underline-offset-4">未登録</Link>
                    </TableData>
                </TableHeader>
                <p className="mt-2 text-sm">※お名前・住所・生年月日を変更する場合、再度本人確認が必要になります。</p>
            </Section>

            <Section>
                <TableHeader title='投資家情報' link="/account/edit/member-information">
                    <TableData name="ご職業">
                        <p>{memberInformation.occupation || '-'}</p>
                    </TableData>
                    <TableData name="勒務先">
                       <p>{memberInformation.occupation || '-'}</p>
                    </TableData>
                    <TableData name="年収">
                         <p>{memberInformation.annual_income || '-'}{memberInformation.annual_income && <span>万円</span>}</p>
                    </TableData>
                    <TableData name="金融資産">
                        <p>{memberInformation.financial_assets || '-'}{memberInformation.financial_assets && <span>万円</span>}</p>
                    </TableData>
                    <TableData name="投資年数">
                       <p>{memberInformation. investment_year || '-'}{memberInformation.investment_year && <span>年</span>}</p>
                    </TableData>
                    <TableData name="投資資金">
                        <p>{memberInformation.investment_funds || '-'}</p>
                    </TableData>
                </TableHeader>
            </Section>

            <Section>
                <TableHeader title='口座情報' link="/bank-account">
                    <TableData name="銀行名">
                        <p>{bankAccount.bank_name || '-'}</p>
                    </TableData>
                    <TableData name="支店名">
                        <p>{bankAccount.branch_name || '-'}</p>
                    </TableData>
                    <TableData name="口座種別">
                        <p>{bankAccount.account_type || '-'}</p>
                    </TableData>
                    <TableData name="口座番号">
                        <p>{bankAccount.account_number || '-'}</p>
                    </TableData>
                    <TableData name="口座名義人">
                        <p>{bankAccount.account_holder || '-'}</p>
                    </TableData>
                </TableHeader>
                <p className="mt-2 text-sm">※必ず投資家様名義の口座をご登録ください。</p>
            </Section>
        </React.Fragment>
    )
}

export default Account;