export type VirtualAccount = {
  vaBankName: string;
  vaHolderNameKana: string;
  vaBranchName: string;
  vaAccountNumber: string;
  vaType: string
}

export const initialVirtualAccount: VirtualAccount = {
  vaAccountNumber: '',
  vaBankName: '',
  vaBranchName: '',
  vaHolderNameKana: '',
  vaType: '',
};

export type WithdrawType = {
  amountAvailable?: number;
  inProgressAmount?: number;
  bankAccountNumber?: string;
  bankName?: string;
  branchName?: string;
  accountType?: string;
  accountHolder?: string;
}

export const initialWithdraw: WithdrawType = {
  amountAvailable: 10000,
  inProgressAmount: 0,
  bankAccountNumber: '',
  bankName: '',
  branchName: '',
  accountType: '',
  accountHolder: ''
};


export type WithdrawHistoryType = {
  amount?:string;
  withdraw_date?:string;
  status?:string;
}


export interface DepositType extends VirtualAccount, WithdrawType {
}


export const initialDepositWithdraw: DepositType = { ...initialWithdraw, ...initialVirtualAccount };