import {ComingSoonFundType, FundListType, FundType, RecruitingType} from "../../store/fund";

const dummyRecruiting: RecruitingType[] = [
    {
        id: 1,
        units: 657,
        name: "a",
        name_display: "先着",
        //booked_units: number;
        units_remainder: 60,
        start: "2023年08月24日",
        end: "2023年08月28日",
        result_date: "2023年09月20日",
        is_finished: true
    },
];
export const dummyFunds: FundType[] = [
    {
        id: 1,
        slug: "dummy-data",
        amount_raised: 16200,
        total_investment: 12333,
        status: "募集中",
        name: "みつばち5号ファンド",
        investment_amount:10000,
        capital_interest: "6.50",
        duration: "1",
        duration_type: "年間",
        operation_start: "2023年09月20日",
        operation_end: "2024年09月19日",
        unit: 100000,
        number_of_units: 1620,
        min_num_unit: 10,
        max_num_units: 100,
        total_distributions: 1,
        transaction_type: "電子取引",
        recruiting: dummyRecruiting,
    },
    {
        id: 2,
        slug: "dummy-data2",
        amount_raised: 16200,
        total_investment: 12333,
        status: "募集中",
        name: "みつばち5号ファン2ド2",
        capital_interest: "6.50",
        duration: "1",
        duration_type: "年間",
        operation_start: "2023年09月20日",
        operation_end: "2024年09月19日",
        unit: 100000,
        number_of_units: 1620,
        min_num_unit: 10,
        max_num_units: 100,
        total_distributions: 1,
        transaction_type: "電子取引",
        recruiting: dummyRecruiting
    },
];

const dummyComingFund: ComingSoonFundType = {
    thumbnail: "path-to-thumbnail",
    detail: {
        title: "Coming Fund 1",
        sub_title: "募集前",
        note: "Note for coming fund 1",
        published: "2023-08-24",
    },
};

const dummyComingFunds: FundListType = {
    comingSoon: dummyComingFund,
};

const dummyFund: FundListType = {
    funds: dummyFunds,
};

export function useFund(): { funds: FundType[]; comingSoon: ComingSoonFundType | null } {
    return {
        funds: dummyFund.funds || [],
        comingSoon: dummyComingFunds.comingSoon || null,
    };
}
