import React, {useRef, useState} from 'react';
import {ErrorList, ErrorsType} from "../../components/ui/form/Error";
import {Section} from "../../components/layouts/section";
import {Link, useNavigate} from "react-router-dom";
import Account from "./account";
import CampaignBanner from "../../components/ui/CampaignBanner";
import MyNumberLink from "../../components/ui/MyNumberLink";

interface CodeInputProps {
  handleCode: (ev: React.ChangeEvent<HTMLInputElement>, value: string, index: number) => void;
  handleKey: (ev: React.KeyboardEvent<HTMLInputElement>, index: number) => void;
  char: string;
  index: number;
  maxLength: number;
}

const VerificationInput: React.FC<CodeInputProps> = ({index, handleCode, handleKey, char, maxLength}) => {

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const pattern = /^\d*$/;
    const target = ev.currentTarget as HTMLInputElement;
    const isValidChar = pattern.test(target.value);

    if (!isValidChar) return;

    handleCode(ev, target.value, index);
  };
  const handleFocus = (ev: React.FocusEvent<HTMLInputElement>) => {
    (ev.currentTarget as HTMLInputElement).select();
  };

  return (
    <input
      type='text'
      inputMode='numeric'
      autoComplete='one-time-code'
      className='w-10 sm:w-12 h-12 sm:h-14 px-3 sm:px-4 border-2 border-primary-bg text-lg font-bold rounded outline-primary-main'
      onChange={handleChange}
      onKeyDown={(ev) => handleKey(ev, index)}
      value={char}
      onFocus={handleFocus}
      maxLength={maxLength}
    />
  );
};

const codeLength = 6;

interface VerificationCodeProps {
  getOPT?: () => void;
  email: string;
  buttonRef: React.MutableRefObject<HTMLButtonElement>;
}

const OtpVerification: React.FC<VerificationCodeProps> = ({email, getOPT, buttonRef}) => {
  const navigate = useNavigate();
  const emptyCode = Array(codeLength).fill('');
  const [code, setCode] = useState(emptyCode);
  const [errors, setErrors] = useState<ErrorsType>();
  const handleCompletion = () => {
    navigate('/account');
  };
  const handleCode = (ev: React.ChangeEvent<HTMLInputElement>, value: string, index: number) => {
    const newCode = [...code];
    const remainingFields = codeLength - index;
    const newValue = value.length ? value.split('', remainingFields) : [''];
    const newValueSize = value.length ? value.length : 1;
    const target = ev.currentTarget as HTMLInputElement;

    newCode.splice(index, newValueSize, ...newValue);
    setCode(newCode);

    if (value.length && value.length < codeLength && index !== codeLength - 1) {
      (target.nextElementSibling as HTMLInputElement || null).focus();
    }
    if (value.length === codeLength) {
      target.blur();
    }
  };

  const handleKey = (ev: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const target = ev.currentTarget as HTMLInputElement;
    if (ev.key === 'Backspace' && target.value === '' && index) {
      (target.previousElementSibling as HTMLInputElement || null).focus();
    }
    if (ev.key === 'ArrowLeft') {
      const prevElement = target.previousElementSibling as HTMLInputElement || null;
      if (prevElement) prevElement.focus();
    }
    if (ev.key === 'ArrowRight') {
      const nextElement = target.nextElementSibling as HTMLInputElement || null;
      if (nextElement) nextElement.focus();
    }
  };

  return (
    <React.Fragment>
      <div>
        <CampaignBanner/>
        <MyNumberLink/>
        <Section className="mt-10">
          <h2 className="text-xl f mb-4">パスコードの入力</h2>
        </Section>
        <form method='post'>
          <Section className='text-base bg-secondary-bg text-lg py-3'>
            <p>{email} に確認コードを送信いたしました。</p>
            <p className="mt-3">確認コードをご入力の上<strong>「変更完了」</strong>ボタンをクリックください。</p>
          </Section>
          <Section>
            <div className='bg-white flex flex-col gap-5 items-center'>
              <label className='text-base tracking-widest'>確認コード(数字6桁)</label>
              <div className='flex max-[300px]:gap-1 max-[480px]:gap-2 gap-3'>
                {code.map((char, index) => (
                  <VerificationInput
                    key={index}
                    handleCode={handleCode}
                    handleKey={handleKey}
                    char={char}
                    index={index}
                    maxLength={codeLength}
                  />
                ))}
              </div>
              <ErrorList name='otp' errors={errors}/>
              {/* TODO: Add redirect link */}
              <p className='text-base'>※メールが届かない場合は<Link to=""
                                                                    className="underline text-primary-main underline-offset-4">こちら</Link>をクリックして下さい。
              </p>
            </div>
            <div
              className='mt-12 flex flex-col items-center justify-center'>
              <button
                onClick={handleCompletion}
                type='submit'
                className='flex items-center tracking-widest justify-center rounded border-4 px-2 text-base sm:text-lg font-bold text-white bg-primary-main border-primary-main py-2.5 w-[215px] max-[215px]:w-full'>
                変更完了
              </button>
            </div>
          </Section>
        </form>
      </div>
    </React.Fragment>
  );
};

export default OtpVerification;