import React from "react";
import CampaignBanner from "../../components/ui/CampaignBanner";
import MyNumberLink from "../../components/ui/MyNumberLink";
import {Recruiting} from "./Recruitng";
import { useFund } from "./fundDummy";

const Fund: React.FC = () => {
   const { funds, comingSoon } = useFund();

  return (
    <React.Fragment>
      <CampaignBanner />
      <MyNumberLink />
       {
           funds.filter((val) => val.status === '募集前' || val.status === '募集中')
            .map((value, index) => (
                <Recruiting key={index} fund={value} />
            ))
       }
    </React.Fragment>
  );
};

export default Fund;