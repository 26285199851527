import React, {useState} from "react";
import CampaignBanner from "../../components/ui/CampaignBanner";
import MyNumberLink from "../../components/ui/MyNumberLink";
import {Section} from "../../components/layouts/section";
import Image from "../../assets/noimage.jpg";
import {TableData, TableHeader} from "../../components/layouts/tableLayout";
import {Link, useParams} from "react-router-dom";
import {FaChevronCircleRight} from "react-icons/fa";
import {InvestmentDetailFundType} from "../../store/account";


const investmentDetail: InvestmentDetailFundType[] = [
  {
  name: "みつばち3号ファンド",
  slug: "fund-a",
  amount_of_investment: 100000,
  unit: 1000,
  image: "fund_a_image.jpg",
  def_time: "2023-08-01",
  duration: "5 years",
  duration_type: "Fixed",
  operation_start: "2023-09-01",
  operation_end: "2028-09-01",
  percent: 7,
  fund_type: 'T',
  dividends: [],
  next_dividend: {
    receive_date: "2023-09-30",
    order: 2,
    receive_money: 120,
  },
  paper_file: null,
},

]


const InvestmentStatusDetails: React.FC = () => {
  const [funds] = useState<InvestmentDetailFundType[]>(investmentDetail);
  const { slug } = useParams<{ slug: string }>();
  const fund = funds.find((val) => val.slug === slug);
  return (
    <div>
      <CampaignBanner/>
      <MyNumberLink/>
      <Section className="my-8">
        <div className="grid grid-cols-3 gap-8">
          <figure className="col-span-1 h-[150px] overflow-hidden border">
            <img className="w-full h-full object-cover" src={Image} alt=""/>
          </figure>
          <div className="col-span-2 flex flex-col justify-between ">
            <div className="text-primary-dark text-sm pb-2">
              <span className="bg-secondary-dark text-primary-dark py-1.5 px-3 mr-2">運用中</span>
            </div>
            <h3 className="text-xl sm:text-2xl text-primary-dark font-bold">みつばち3号ファンド</h3>
            <div>
              <p>次回分配予定日：2024年7月31日(1/1回目) </p>
              <p>予定分配金：2,500円 *税引前</p>
            </div>
          </div>
        </div>
        <TableHeader>
          <TableData name="出資日">
            {fund?.def_time}
          </TableData>
          <TableData name="想定利回り">

          </TableData>
          <TableData name="出資金額">

          </TableData>
          <TableData name="出資口数">

          </TableData>
          <TableData name="想定運用期間">

          </TableData>
          <TableData name="取引種別">

          </TableData>
        </TableHeader>
        <div className="my-5">
          {/* TODO : link redirect */}
          <Link
            to={`https://mitsubachi.com/fund_list//`}
            className="sm:text-lg justify-end items-center text-primary-dark tracking-widest flex mt-2">
            サイトでファンド詳細を確認
            <FaChevronCircleRight className="fill-primary-main h-5 w-5"/>
          </Link>
        </div>
        <p
          className="text-accent-main my-8">*運用期間中にファンドの物件売却が決定し、出資金の早期償還と配当のお支払いを行うことがございます。</p>
        <div>
          <h2>交付書面</h2>
          <div className="border-y-2 border-accent-bg mt-2">
            <div className=" flex justify-between border-b last:border-b-0 py-4">
              <div>
                <p className="text-secondary-text text-sm">閲覧日｜2023年3月14日 12:35</p>
                <h3 className="text-lg">契約成立前書面</h3>
              </div>
              <div className="flex items-center ">
                <Link className="border-primary-dark border-2 rounded-xl text-primary-dark font-bold text-center w-24 py-2" to="">閲覧</Link>
              </div>
            </div>
          </div>


        </div>
      </Section>
    </div>
  )
}

export default InvestmentStatusDetails;