import React from "react";
import {Section} from "../../../components/layouts/section";
import {ApplicationStep} from "./ApplicationStep";
import ContactBanner from "../../../components/layouts/ContactBanner";
import {Link} from "react-router-dom";


export const ApplicationCompleted: React.FC = () => {
  return (
    <React.Fragment>
      <ApplicationStep step={3}/>
      <Section>
        <h2 className="text-2xl mb-1">出資完了</h2>

        <h3 className="text-accent-main pt-2">《面前の場合》</h3>
        <p>この度は<strong>「みつばち5号ファンド」</strong>へ出資申込をいただき誠にありがとうございます。</p>
        <p className="py-4">3営業日以内にご登録されているご住所に契約書やその他書類（?）を郵送させていただきます。</p>
        <p>書類ご記入後、返信用封筒にて書類をご返送ください。</p>
        <p className="py-4">内容の確認後、出資完了とさせていただきます。(?)</p>

        <h3 className="text-accent-main pt-8">《クラファンの場合》</h3>
        <p>この度は<strong>「みつばち5号ファンド」</strong>へご出資いただき誠にありがとうございます。</p>
        <p className="py-4">みつばち5号ファンドの契約成立時書面を発行いたしました。</p>
        <p>*各種交付書面は、<Link className="text-primary-main underline-offset-4 underline" to="" >こちら</Link>からご確認いただけます。</p>
      </Section>
      <ContactBanner>
        <p className="py-2">その他、ご不明点等ございましたら<span className="hidden min-[500px]:block"></span> こちらからお問い合わせください。</p>
      </ContactBanner>
    </React.Fragment>
  )
}