import React, {useState} from "react";
import CampaignBanner from "../../components/ui/CampaignBanner";
import MyNumberLink from "../../components/ui/MyNumberLink";
import {Section} from "../../components/layouts/section";

import {Link} from "react-router-dom";
import ContactBanner from "../../components/layouts/ContactBanner";
import {InputField} from "../../components/ui/form/InputField";
import {initialDepositWithdraw, WithdrawType} from "../../store/transaction";
import {convertToNumber} from "../../utlis/numbers";
import {ErrorsType} from "../../components/ui/form/Error";
import WithDrawCompleted from "./withDrawCompleted";

const WithDraw: React.FC = () => {
  const [state, setState] = useState<WithdrawType>(initialDepositWithdraw);
  const [amount, setAmount] = useState<number>(0);
  const [errors, setErrors] = useState<ErrorsType>();
  const [completed, setCompleted] = useState(false);
  const handleCompletion = () => {
    setCompleted(true);
  };

  return (
    <React.Fragment>
      <CampaignBanner/>
      <MyNumberLink/>
      {!completed ?
        <div>
          <Section className="mt-10">
            <h2 className="text-xl mb-4">払戻申請</h2>
          </Section>
          <Section className='text-base bg-secondary-bg text-lg py-3'>
            {/* TODO : fund info */}
            <p className="">お客様は現在「みつばち5号ファンド」の抽選募集に<b className="text-accent-main">10,000円</b>（1口）申込中です。
            </p>
          </Section>
          <Section className="flex flex-col items-center mt-10">
            <p className="text-center text-sm pb-10">▼払戻申請額を半角数字でご入力ください▼</p>
            <div className="flex flex-col w-full sm:w-1/2 ">
              <p className="text-lg">申請可能額: <strong className="">{state.amountAvailable}円</strong></p>
              <InputField
                value={amount}
                onChange={(e) => {
                  setAmount(convertToNumber(e.target.value) || 0);
                }} name="withdraw"
                width="w-full "
                iconRight="円"
                errors={errors}
              />
            </div>
          </Section>
          <Section>
            <h3>《払戻についての注意事項》</h3>
            <ul className="square leading-7 mt-2 tracking-widest">
              <li>当日の23時59分までに預託金の払い戻し申請をいただいてから、当日を含む5営業日以内に、ご指定の口座にお振込みいたします。</li>
              <li>ご依頼いただいた金額から払戻手数料（一律250円）を差し引いた金額を送金させていただきます。</li>
              <li>抽選で落選した投資家様、先着順ファンドで出資できなかった投資家様に関する払い戻しは、抽選結果発表日（先着順の場合、完売日）から1週間以内・申込額以上限り手数料無料といたします。</li>
            </ul>
          </Section>
          <Section className='text-base bg-secondary-bg text-lg py-3'>
            <label className='inline-flex cursor-pointer items-center'>
              <input
                required
                type='checkbox'
                className='h-5 w-5 form-checkbox text-secondary-main bg-primary-dark'
              />
              <span className='ml-2 text-base font-bold md:text-lg lg:text-xl'>
                  上記の内容に同意して払戻申請をする。
                </span>
            </label>
          </Section>
          <Section className="flex max-[400px]:flex-col flex-row justify-center items-center gap-5 mt-10 mb-10">
            <Link
              to='/'
              className='flex items-center justify-center rounded border-4 px-2 text-lg font-bold text-secondary-text border-secondary-text py-2.5 bg-white h-[50px] w-[215px] max-[215px]:w-full'>
              申請しない
            </Link>
            <button
              type="submit"
              onClick={handleCompletion}
              className='flex items-center justify-center rounded border-4 px-2 text-lg font-bold text-white bg-primary-main border-primary-main py-2.5 h-[50px] w-[215px] max-[215px]:w-full'>
              申請する
            </button>
          </Section>
          <ContactBanner>
            <p className="py-2">払い戻しに関して、ご不明点等ございましたら<br/>こちらからお問い合わせください。</p>
          </ContactBanner>
        </div> : <WithDrawCompleted/>}
    </React.Fragment>
  )
}

export default WithDraw;