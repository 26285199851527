import React from 'react';
import {NewsType} from "../../store/post";
import {Link} from "react-router-dom";
import {convertToJapaneseDate} from "../../utlis/date";

interface NewsProps {
  className?: string;
}
type ExtendedNewsType = NewsType & NewsProps;

export const NewsData: React.FC<ExtendedNewsType> = ({ className, ...props }) => {
  return (
    <li className={`${className} border-b w-full border-accent-bg py-3`}>
      <Link to={`/news/${props.slug || ''}`}>
        <time className="text-xs text-secondary-text">{convertToJapaneseDate(props.created_date || '')}</time>
        <h3 className="text-lg">{props.title}</h3>
      </Link>
    </li>
  )
}