import React, {useRef, useState} from 'react';
import CampaignBanner from "../../components/ui/CampaignBanner";
import MyNumberLink from "../../components/ui/MyNumberLink";
import {Section} from "../../components/layouts/section";
import {useAccount} from "./accountDummy";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import {ErrorList, ErrorsType} from "../../components/ui/form/Error";
import {Link} from "react-router-dom";
import OtpVerification from "./OtpVerification";

const EditEmail: React.FC = () => {
  const {loginInformation} = useAccount();
  const [state, setState] = useState<{ username: string; password: string }>({username: '', password: ''});
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [completed, setCompleted] = useState(false);
  const handleCompletion = () => {
    setCompleted(true);
  };
  const buttonRef = useRef<HTMLButtonElement>(null!);
  const buttonRef2 = useRef<HTMLButtonElement>(null!);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };
  const [errors, setErrors] = useState<ErrorsType>();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setState((prev) => ({...prev, [name]: value}));
  };
  return (
    <React.Fragment>
      {!completed ?
        <div>
          <CampaignBanner/>
          <MyNumberLink/>
          <Section className="mt-10">
            <h2 className="text-xl mb-4">登録メールアドレスの変更</h2>
          </Section>
          <Section className="bg-secondary-bg py-2">
            <p className="text-base leading-10">現在登録されているメールアドレスをご確認の上、マイページのログインパスワードを入力し<strong>「変更する」</strong>ボタンを押してください。
            </p>
          </Section>
          <form method='post' autoComplete='off'>
            <Section>
              <div className='mt-8'>
                <label className='font-normal text-base'>現在のメールアドレス</label>
                <p className='sm:text-xl mb-3 font-bold'>{loginInformation.email}</p>
              </div>
              <div className='mt-8'>
                <label className='font-normal text-base'>変更したいメールアドレス</label>
                <div className='relative mt-1.5'>
                  <input
                    type='email'
                    name='username'
                    value={state.username}
                    onChange={handleChange}
                    autoComplete='off'
                    placeholder="xxx-xx@gmail.com"
                    readOnly={true}
                    onFocus={(e) => {
                      e.currentTarget.removeAttribute('readonly');
                    }}
                    className='h-12 p-4 border-primary-grey rounded border w-full'/>
                </div>
                <p className='text-xs px-1 text-secondary-text'>※半角英数字で入力してだくさい。</p>
                <ErrorList name='username' errors={errors}/>
              </div>
              <div className='mt-8'>
                <label className='font-normal text-base'>ログインパスワード</label>
                <div className='relative mt-1.5'>
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    name='password'
                    value={state.password}
                    onChange={handleChange}
                    readOnly={true}
                    autoComplete='off'
                    onFocus={(e) => {
                      e.currentTarget.removeAttribute('readonly');
                    }}
                    className='h-12 p-4 border-primary-grey rounded-md border w-full'/>
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                    onClick={togglePasswordVisibility}
                  >
                    {isPasswordVisible ? (<FaEye/>) : (<FaEyeSlash/>)}
                  </button>
                </div>
                <ErrorList name='password' errors={errors}/>
                <p className='text-xs px-1 text-secondary-text'>※半角英数字で入力してだくさい。</p>
              </div>
            </Section>
            <Section className="bg-secondary-bg py-3 mb-5">
              <p>※ご入力されたメールアドレスに確認コードが送信されます。</p>
              <p> 1時間以内にご確認の上、変更を完了させてください。1時間以上経過してしまうと、変更手続きがリセットされてしまうのでご注意ください。</p>
            </Section>
            <Section className="flex max-[400px]:flex-col flex-row justify-center items-center gap-5 mt-8 mb-10">
              <Link to='/account'
                    className='flex items-center justify-center rounded border-4 px-2 text-base font-bold text-secondary-text border-secondary-text py-2.5 bg-white w-[215px] max-[215px]:w-full'>
                戻る
              </Link>
              <button onClick={handleCompletion}
                      className='flex items-center justify-center rounded border-4 px-2 text-base font-bold text-white bg-primary-main border-primary-main py-2.5 w-[215px] max-[215px]:w-full'>
                変更する
              </button>
            </Section>
          </form>
        </div> : <OtpVerification email={state.username} buttonRef={buttonRef2}/>}
    </React.Fragment>
  )
}
export default EditEmail;