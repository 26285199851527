import React, { useEffect, useState  } from 'react';

import CampaignBanner from "../../components/ui/CampaignBanner";
import MyNumberLink from "../../components/ui/MyNumberLink";
import {Section} from "../../components/layouts/section";
import {InputField} from "../../components/ui/form/InputField";
import {genderOptions, PersonalInformationType} from "../../store/account";
import {useAccount} from "./accountDummy";
import {ErrorsType} from "../../components/ui/form/Error";
import {Link, useNavigate } from "react-router-dom";
import {RadioButton } from "../../components/ui/form/RadioButton";
import {InputRow} from "../../components/layouts/InputRow";


const EditPersonalInformation: React.FC = () => {
    const navigate = useNavigate();
    const { personalInformation} = useAccount();
    const [state, setState] = useState<PersonalInformationType>(personalInformation);
    const [errors, setErrors] = useState<ErrorsType>();
    const handleCompletion = () => {
        navigate('/account');
      };
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
    return(
       <React.Fragment>
            <CampaignBanner/>
            <MyNumberLink/>
            <Section className="mt-10">
                <h2 className="text-xl mb-4">登録基礎情報の変更</h2>
            </Section>
           <Section className="bg-secondary-bg py-2">
               <p className="text-base leading-10">※お名前・住所・生年月日を変更する場合、再度本人確認が必要になります。</p>
           </Section>
           <Section>
               <InputRow title="お名前" >
                   <InputField
                       onChange={handleChange}
                        name='last_name'
                        value={state.last_name || ''}
                        width='max-[400px]:w-full lg:w-1/2 '
                        errors={errors}
                        required
                   />
                   <InputField
                       onChange={handleChange}
                        name='first_name'
                        value={state.last_name || ''}
                        width='max-[400px]:w-full lg:w-1/2'
                        errors={errors}
                        required
                   />
                </InputRow>
               <InputRow title="お名前（カナ）" >
                   <InputField
                       onChange={handleChange}
                        name='last_name_kana'
                        value={state.last_name_kana || ''}
                        width='max-[400px]:w-full lg:w-1/2 '
                        errors={errors}
                        required
                   />
                   <InputField
                        onChange={handleChange}
                        name='first_name_kana'
                        value={state.first_name_kana || ''}
                        width='max-[400px]:w-full lg:w-1/2'
                        errors={errors}
                        required
                   />
                </InputRow>
               <InputRow title="性別" >
                <RadioButton
                    options={genderOptions}
                    labelKey='label'
                    valueKey='value'
                    value={state.sex}
                    onChange={handleChange}
                    name='sex'
                    checked
                />
                </InputRow>
               <InputRow title="生年月日" >
                   <InputField
                      name='birth_date'
                      type='date'
                      onChange={handleChange}
                      value={state.birth_date || ''}
                      width='max-[400px]:w-full lg:w-1/2 '
                      errors={errors}
                      required
                   />
                </InputRow>
               <InputRow title="電話番号" >
                   <InputField
                      name='phone_number'
                      type='text'
                      onChange={handleChange}
                      value={state.phone_number || ''}
                      width='max-[400px]:w-full lg:w-1/2 '
                      errors={errors}
                      required
                   />
                </InputRow>
               <InputRow title="住所" >
                   <InputField
                      name='postal_code'
                      type='text'
                      onChange={handleChange}
                      value={state.postal_code || ''}
                      width='max-[400px]:w-full lg:w-1/2'
                      className="pl-4"
                      errors={errors}
                      required
                      icon="〒"
                   />
                   <InputField
                      name='prefecture'
                      type='text'
                      onChange={handleChange}
                      value={state.prefecture || ''}
                      width='max-[400px]:w-full lg:w-1/2 '
                      errors={errors}
                      required
                   />
                   <InputField
                      name='city_ward'
                      type='text'
                      onChange={handleChange}
                      value={state.city_ward || ''}
                      width='max-[400px]:w-full lg:w-1/2 '
                      errors={errors}
                      required
                   />
                   <InputField
                      name='block_name '
                      type='text'
                      onChange={handleChange}
                      value={state.block_name || ''}
                      width='max-[400px]:w-full lg:w-1/2 '
                      errors={errors}
                      required
                   />
                </InputRow>
           </Section>
           <Section className="bg-secondary-bg py-4 mb-5">
               <p>上記の内容で変更しますか？</p>
           </Section>
           <Section className="flex max-[400px]:flex-col flex-row justify-center items-center gap-5 mt-8 mb-10">
              <Link
                  to='/account'
                  className='flex items-center justify-center rounded border-4 px-2 text-lg font-bold text-secondary-text border-secondary-text py-2.5 bg-white h-[50px] w-[215px] max-[215px]:w-full'>
                    戻る
              </Link>
               <button
                   type="submit"
                   onClick={handleCompletion}
                   className='flex items-center justify-center rounded border-4 px-2 text-lg font-bold text-white bg-primary-main border-primary-main py-2.5 h-[50px] w-[215px] max-[215px]:w-full'>
                    変更する
               </button>
           </Section>
       </React.Fragment>
    )
}

export default EditPersonalInformation;