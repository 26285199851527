import React from 'react';
import {ErrorList, ErrorListProps} from './Error';
import {GoAlertFill} from "react-icons/go";


interface InputFieldProps extends ErrorListProps {
	width?: string;
	label?: string;
	type?: string;
	placeholder?: string;
	required?: boolean;
	showRequired?:boolean;
	className?: string;
	suggestion?: string;
	value: string | number;
	icon?:string;
	iconRight?:string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	style?:string
}

export const InputField: React.FC<InputFieldProps> = ({
	                                                      onChange,
	                                                      label,
	                                                      name,
	                                                      value,
	                                                      className='',
	                                                      required,
	                                                      placeholder,
	                                                      suggestion,
	                                                      type = 'text',
	                                                      width = 'w-full',
	                                                      disabled = false,
														  icon,
														  iconRight,
														  showRequired,
														  style="py-2.5 px-3",
	                                                      errors,
                                                      }) => {

	return (
		<div className={`${width} flex flex-wrap flex-col py-2`}>
			{label &&
				<label className='text-base pb-1'> {label}
					{showRequired &&
						<span className='text-accent-main text-sm'> ※必須</span>
					}
				</label>
			}
			<div className={`relative border border-accent-bg rounded disabled:opacity-50 ${className} flex items-center`}>
				<input
					type={type}
					value={value}
					name={name}
					onChange={onChange}
					required={required}
					placeholder={placeholder}
					className={`rounded focus:outline-none w-full ${style}`}
					disabled={disabled}
				/>
				{icon && <span className="absolute text-secondary-text -ml-3">{icon}</span> }
				{iconRight &&<span className="absolute right-0 bottom-0 pb-2 text-lg font-bold text-accent-bg mr-1">{iconRight}</span>}
			</div>
			{/*<ul className="bg-accent-main mt-1 text-sm">*/}
			{/*	<li className="px-2 flex items-center text-white"><GoAlertFill className="mr-1"/> Error</li>*/}
			{/*</ul>*/}
			<span className='text-xs opacity-50'>{suggestion}</span>
			<ErrorList name={name} errors={errors}/>
		</div>
	);
};