import React, {useEffect, useState} from "react";
import {Section} from "../../components/layouts/section";
import {ErrorsType} from "../../components/ui/form/Error";
import {useAccount} from "../account/accountDummy";
import {
  assetTypeOptions, experienceChoice,
  incomeSourceOptions, investmentFundsOptions, investmentPolicyOptions,
  InvestorRegistrationType,
  occupationOptions,
  purposeOfInvestOptions, yearsOfInvestOptions
} from "../../store/account";
import SelectField from "../../components/ui/form/SelectField";
import {InputField} from "../../components/ui/form/InputField";
import {RadioButton} from "../../components/ui/form/RadioButton";
import CheckBoxWithInput from "../../components/ui/form/CheckBoxWithInput";
import CheckBox from "../../components/ui/form/CheckBox";
import {ChoiceOptionType} from "../../store";
import {Link} from "react-router-dom";
import {useStepper} from "../../hooks/useStepper";

export const RegistrationStepTwo: React.FC = () => {
  const {incrementCurrentStep, decrementCurrentStep} = useStepper();
  const {investorRegistrationType} = useAccount();
  const [state, setState] = useState<InvestorRegistrationType>(investorRegistrationType);
  const [errors, setErrors] = useState<ErrorsType>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = () => {

  }
  return (
    <div>
      <Section className="mt-5">
        <h2 className="text-xl mb-1">投資家情報</h2>
        <p className="text-base leading-10">
          以下の内容をご確認の上、投資家情報を入力してください。<br/>
          選択項目は最も当てはまるものを選択してください。
        </p>
      </Section>
      <Section>
        <SelectField
          options={occupationOptions}
          valueKey='value'
          label="ご職業"
          labelKey='label'
          value={state.occupation || ''}
          width='max-[400px]:w-full '
          required
          name='occupation'
          errors={errors}
          onChange={handleChange}
        />
        <InputField
          label="勤務先"
          onChange={handleChange}
          name='workplace'
          value={state.workplace || ''}
          width='max-[400px]:w-full '
          suggestion="※学生の場合は学校名を、主婦の場合は空白にしてください。"
          errors={errors}
        />
        <InputField
          label="年収"
          onChange={handleChange}
          name='annual_income'
          value={state.annual_income || ''}
          width='max-[400px]:w-full w-1/2 '
          errors={errors}
          iconRight="万円"
          suggestion="※半角数字で入力してください。"
          showRequired
          required
        />
        <SelectField
          options={incomeSourceOptions}
          valueKey='value'
          label="ご職業"
          labelKey='label'
          value={state.income_source || ''}
          width='max-[400px]:w-full'
          required
          showRequired
          name='income_source'
          placeholder="選択してください"
          errors={errors}
          onChange={handleChange}
        />
        <SelectField
          options={purposeOfInvestOptions}
          valueKey='value'
          label="ご職業"
          labelKey='label'
          value={state.investment_purpose || ''}
          width='max-[400px]:w-full '
          required
          showRequired
          name='investment_purpose'
          placeholder="選択してください"
          errors={errors}
          onChange={handleChange}
        />
        <RadioButton
          options={investmentPolicyOptions}
          labelKey='label'
          label="投資方針"
          valueKey='value'
          value={state.investment_policy}
          onChange={handleChange}
          name='investment_policy'
          checked
          required
          showRequired
        />
        <CheckBoxWithInput
          options={assetTypeOptions}
          values={state.asset_type}
          onChange={(values) => {
            setState((prev) => ({...prev, 'asset_type': values}));
          }}
          valueKey='value'
          labelKey='label'
          inputName='asset_amount'
          checkboxName='asset_name'
          name='asset_type'
          className='ml-2 grid gap-x-10 sm:grid-cols-2 mt-3'
          errors={errors}
          iconRight="万円"
          label='資産状況(複数選択・入力可)'
        />
        <CheckBox
          <ChoiceOptionType>
          label='投資経験（複数選択可）'
          options={experienceChoice}
          values={state.investment_experience}
          onChange={(values) => {
            setState((prev) => ({...prev, 'investment_experience': values}));
          }}
          valueKey='value'
          labelKey='label'
          name='investment_experience'
          className='ml-2 grid gap-1 sm:grid-cols-2'
          errors={errors}
        />
        <SelectField
          options={yearsOfInvestOptions}
          valueKey='value'
          labelKey='label'
          name='investment_year'
          width='max-[400px]:w-full lg:w-3/4 sm:mr-10'
          value={state.investment_year}
          label='投資経験年数'
          onChange={handleChange}
          errors={errors}
          required
          showRequired
        />
        <SelectField
          options={investmentFundsOptions}
          valueKey='value'
          labelKey='label'
          name='investment_funds'
          width='max-[400px]:w-full lg:w-3/4 sm:mr-10'
          value={state.investment_funds}
          label='投資資金'
          onChange={handleChange}
          errors={errors}
          required
          showRequired
        />
      </Section>
      <Section className="bg-secondary-bg py-2 mb-4">
        <p className="">確認事項 <span className='text-accent-main'> ※必須</span></p>
      </Section>
      <Section className="flex flex-col gap-y-2">
        <label className='flex items-center'>
          <input
            type='checkbox'
            name=""
            value=""
            onChange={handleChange}
            className='mr-2 w-4 h-4 accent-pink-500'
          />
          元本・配当利回りの保証がないことに同意する。
        </label>
        <label className='flex items-center'>
          <input
            type='checkbox'
            name=""
            value=""
            onChange={handleChange}
            className='mr-2 w-4 h-4 accent-pink-500'
          />
          {/* TODO link*/}
          <span><Link className="underline-offset-4 underline text-primary-light" to="">反社会勢力に対する基本方針</Link>に同意する。</span>
        </label>
        <label className='flex items-center flex-row '>
          <input
            type='checkbox'
            name=""
            value=""
            onChange={handleChange}
            className='mr-2 w-4 h-4 accent-pink-500'
          />
          {/* TODO link*/}
          <span><Link className="underline-offset-4 underline text-primary-light" to="">電子交付に関する同意事項</Link>に同意する。</span>
        </label>
      </Section>
      <div className="mb-10 pt-5 px-6 w-[450px] max-[450px]:w-full mx-auto">
        <div
          className='mt-8 mb-8  flex flex-col items-center justify-center'>
          <button
            onClick={incrementCurrentStep}
            type='button'
            className='flex items-center tracking-widest justify-center rounded border-4 px-2 text-base sm:text-lg font-bold text-white bg-accent-main border-accent-main py-2.5 w-full'>
            本人確認書類の提出に進む
          </button>
        </div>
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-8 bg-white text-gray-500">Or</span>
          </div>
        </div>
        <div className="flex items-center justify-center gap-4">
          <button
            type='button'
            onClick={decrementCurrentStep}
            className="border-2 rounded border-secondary-text text-white flex justify-center bg-secondary-text items-center text-center py-3 w-52 h-10 mt-8">
            基本情報へ戻る
          </button>
          <Link
            to=""
            className="border-2 rounded border-secondary-text text-white flex justify-center bg-secondary-text items-center text-center py-3 w-52 h-10 mt-8">
            ログアウト
          </Link>
        </div>
      </div>
    </div>
  )
}
