import { useState, useCallback, FormEvent } from 'react';

type FormValues = {
  [key: string]: any;
};

type SubmitProps = {[key: string]: string} | string | undefined;

type FormErrors = {
  [key: string]: any;
};

export type FormCallback<T extends FormValues, ErrorT extends FormErrors, SubmitPropsT extends SubmitProps = undefined> = (
  values: T,
  setErrors: (errors: ErrorT) => void,
  props?: SubmitPropsT
) => void;

export type FormHook<T extends FormValues, ErrorT extends FormErrors, SubmitPropsT extends SubmitProps = undefined> = {
  inputValues: T;
  handleChange: (event: FormEvent<HTMLInputElement>) => void;
	errors: ErrorT | undefined,
  handleSubmit: (event: FormEvent<HTMLFormElement>, props?:SubmitPropsT) => void;
  handleClick?: () => void;
};

type MainFormHook<T extends FormValues, ErrorT extends FormErrors, SubmitPropsT extends SubmitProps = undefined> = {
  handleInitial: (data: T) => void;
} & FormHook<T , ErrorT, SubmitPropsT>;


function useForm<T extends FormValues, ErrorT extends FormErrors, SubmitPropsT extends SubmitProps = undefined>(
  initialValues: T,
  onSubmit: FormCallback<T, ErrorT, SubmitPropsT>,
  onClick?: FormCallback<T, ErrorT, SubmitPropsT>
): MainFormHook<T, ErrorT, SubmitPropsT> {

  const [inputValues, setInputValues] = useState<T>(initialValues);
  const [errors, setErrors] = useState<ErrorT>();

  const handleChange = useCallback((event: FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setInputValues((prevValues) => ({ ...prevValues, [name]: value }));
  }, []);

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>, props?:SubmitPropsT) => {
      event.preventDefault();
      onSubmit(inputValues, setErrors, props);
    },
    [onSubmit, inputValues]
  );

  const handleClick = useCallback((props?:SubmitPropsT) => {
    if (onClick !== undefined) {
      onClick(inputValues, setErrors, props);
    }
  }, [onClick, inputValues])

  const handleInitial = useCallback(
    (data: T) => {
      setInputValues(data);
    },
    [inputValues]
  );

  return {
    inputValues,
    handleChange,
    errors,
    handleSubmit,
    handleInitial,
    handleClick
  };
}

export default useForm;