import React from "react";

export const ApplicationStep:React.FC<{step:number}> = ({step}) => {

  return (
    <div className="w-full pt-14 pb-0 px-6 sm:px-12">
        <div className="flex items-center p-5">
            <div className="flex items-center relative">
                <div className={`${step >= 1 }} ? 'font-bold':''} absolute top-0 left-0 w-9 text-center -mt-8 -ml-1 text-center text-primary-dark`}>申込</div>
                <div className={`${step >= 1  ? 'bg-primary-dark':''} rounded-full transition duration-500 ease-in-out h-7 w-7 border-4  border-primary-dark`}>
                </div>
            </div>
            <div className="flex-auto border-t-4 transition duration-500 ease-in-out border-primary-dark"></div>
            <div className="flex items-center text-white relative">
                <div className={`${step >= 2 }} ? 'font-bold':''} absolute top-0 left-0 w-9 text-center -mt-8 -ml-1 text-center text-primary-dark`}>確認</div>
                <div className={`${step >= 2 ? 'bg-primary-dark':''} rounded-full transition duration-500 ease-in-out h-7 w-7 border-4  border-primary-dark`}>
                </div>
            </div>
            <div className="flex-auto border-t-4 transition duration-500 ease-in-out border-primary-dark"></div>
            <div className="flex items-center text-gray-500 relative">
                <div className={`${step >= 3 }} ? 'font-bold':''} absolute top-0 left-0 w-9 text-center -mt-8 -ml-1 text-center text-primary-dark`}>完了</div>
                <div className={`${step >= 3 ? 'bg-primary-dark':''} rounded-full transition duration-500 ease-in-out h-7 w-7 border-4  border-primary-dark`}>
                </div>
            </div>
        </div>
    </div>
  );
}