import React from "react";
import {Section} from "../../components/layouts/section";
import {Link} from "react-router-dom";

const PasswordChangeCompleted: React.FC = () => {
  return (
    <div>
      <Section className="mt-10">
        <h2 className="text-xl mb-4">ログインパスワードの変更完了</h2>
      </Section>
      <Section className="bg-secondary-bg py-6">
        <p className="text-base leading-10">パスワードが変更されました。</p>
      </Section>
      <Section className="flex max-[400px]:flex-col flex-row justify-center items-center gap-5 mt-8 mb-10">
        <Link to='https://mitsubachi.com'
              className='flex items-center justify-center rounded border-4 px-2 text-lg h-[66px] font-bold text-white bg-primary-main border-primary-main py-2.5 w-[215px] max-[215px]:w-full'>
          ログイン
        </Link>
      </Section>
    </div>
  )
}
export default PasswordChangeCompleted;